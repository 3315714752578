<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 300px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{ language.orders }}</h1>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary ">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ language.orders_feed }}</h3>
                  </div>
                </div>
              </div>
            </template>
              
                

            
              <form>
                <div class="form-group justify-content-center row">
                    <div class="col-xl-4 col-md-10">
                    <el-input
                        placeholder='Orders Feed URL'
                        suffix-icon="el-icon-notebook-1"
                        v-model="formData.feed_url">
                    </el-input>
                    </div>
                    <div class="col-xl-2 col-md-10">
                    <button 
                        type="button"  
                        for="order_feed"
                        class="btn btn-warning form-control wbm-height-auto" 
                        @click="import_feed">{{language.import_orders}}</button>
                    </div>
                </div>
                </form>
            
            
            <div class="m-2 row mx-auto" >{{ msj }}</div>

            <div class="row mx-auto ">
              <h1 class="mx-auto">{{language.orders_table}}</h1>
            </div>
            <div class="row col-md-12 col-l-10 col-xl-7 mx-auto">  
                <el-table
                    class="table-responsive table-flush"
                    header-row-class-name="thead-light"
                    @sort-change="sortChange"
                    @header-click="headerClick"
                    :data="orders.slice((currentPage-1)*pageSize,currentPage*pageSize)"
                    :border="true"
                    :stripe="true"
                    :highlight-current-row="true"
                    :fit="true"
                    :loading="loading">

                    <el-table-column :label="language.OrderID" prop="order_id" width="120px" align="center"/>
            
                    <el-table-column :label="language.Product" prop="product" min-width="150px" align="center"/>

                    <el-table-column :label="language.Quantity" prop="quantity" width="100px" align="center">
                      <template v-slot="scope">
                            <span style="margin-left: 10px">{{ scope.row.product_quantity }}</span>
                            <a> {{ " "+language.pieces }}</a>
                      </template>
                    </el-table-column>

                    <el-table-column :label="language.order_product_price" prop="price" width="130px" align="center">
                      <template v-slot="scope">
                            <span style="margin-left: 10px">{{ scope.row.price }}</span>
                            <a> {{ " "+"USD" }}</a>
                      </template>
                    </el-table-column>

                    <el-table-column :label="language.Date" prop="order_date" width="160px" align="center"/>

                </el-table>
            </div>
            <div class="row mx-auto">
              <el-pagination align='center' 
                @size-change="handleSizeChange" 
                @current-change="handleCurrentChange"
                :current-page="currentPage" 
                :pager-count="3"
                :page-sizes="[1,5,10,20]" 
                :page-size="pageSize" 
                layout="total, sizes, prev, pager, next, jumper" 
                :total="orders.length"
                class="mx-auto m-3 wbm-pagination-layout">
              </el-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderService from '../services/OrderService'
import UserService from '../services/UserService'

import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElPagination,
  ElPopover,
  ElButton,
  ElCollapse,
  ElInput,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  ElSelect,
  ElCollapseItem,
  ElCollapseTransition,
  ElProgress,
  ElNotification,
  ElLoading,
  ElMessage,
  ElAvatar,
  ElLink,
  ElImage
} from "element-plus";

export default {
  name: "light-table",
  components: {
    [ElButton.name]: ElButton,
    [ElPopover.name]: ElPopover,
    [ElPagination.name]: ElPagination,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElCollapse.name]: ElCollapse,
    [ElInput.name]: ElInput,
    [ElRadio.name]: ElRadio,
    [ElRadioButton.name]: ElRadioButton,
    [ElSelect.name]: ElSelect,
    [ElCollapse.name]: ElCollapse,
    [ElProgress.name]: ElProgress,
    [ElNotification.name]: ElNotification,
    [ElAvatar.name]: ElAvatar,
    [ElLink.name]: ElLink,
    [ElImage.name]: ElImage
  },
  data() {
        return {
          formData: {
            file: "",
            feed_url: "",
            currency: "",
          },
          message: "",
          theme: '',
          selected_file: "",
          orders: [],
          currentPage: 1,
          total: 10,
          pageSize: 10,
          activeNames: ['1'],
          radio1: '',
          loading: true,
          currentCompany: [],
          language: '',
        }
      },
  filters: {
        truncate: function(data, num) {
            reqdString = ''
            for(let i=0; i<num; i++) {
                reqdString += data[i]
            }
            return reqdString;
        }
    },
  
  async mounted() {
    this.formData.feed_url = (await UserService.getUserDetails()).data.company.orders_feed_url;
    this.formData.currency = (await UserService.getUserDetails()).data.company.currency;
    this.orderData = (await OrderService.orders()).data;
    this.orders = this.orderData;
  },
  computed: {
     pagedTableData() {
       return this.tableData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
     }
   },
  methods: {

    async import_feed() {
      ElNotification.info({
        title: 'Order Feed',
        message: 'Orders are importing',
        duration: 5000
        });        
      try {
          await OrderService.orders_import({url: this.formData.feed_url})
          ElNotification({
              title: 'Order Feed',
              message: 'Orders successfully imported',
              type: 'success',
            })
          
        } catch (err) {
          console.log(err)
          ElNotification({
              title: 'Order Feed',
              message: 'Import error',
              type: 'error',
            })
        }
        this.orderData = (await OrderService.orders()).data;
        this.orders = this.orderData;
    },
    
    handleSizeChange(val) {
        console.log(`${val} per page`);
        this.currentPage = 1;
        this.pageSize = val;
     },
    handleCurrentChange(val) {
        console.log(`Current page: ${val}`);
        this.currentPage = val;
     },
     handleChange(val) {
        console.log(val);
      },
      handleCommand(command) {
        this.$message('click on item ' + command);
      }

  },
  created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  }
};
</script>
<style scoped>
    .succes_message{
      color: green;
    }
    .fail_message{
      color: red;
    }

    .table_custom_feed{
      display: none;
    }
    
</style>
