<template>
  <div>
    <base-header
    class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
    style="
        min-height: 300px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
    "
    >
    <!-- Mask -->
    <span class="mask bg-gradient-success opacity-8"></span>
    <!-- Header container -->
    <div class="container-fluid d-flex align-items-center">
      <div class="row">
        <div class="col-lg-7 col-md-10">
          <h1 class="display-2 text-white">{{language.users}}</h1>
        </div>
      </div>
    </div>
    </base-header>
    <!-- Modal to add a new user -->
    <modal v-model:show="addUserTab"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0">
            <div class="text-center text-muted mb-4">
                <small>{{language.complete_user_details}}</small>
            </div>
            <!-- Add new user form -->
            <form @submit.prevent>
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-email-83"></i>
                    </span>
                  </div>
                  <input
                    placeholder="Email"
                    class="form-control input-group-alternative"
                    v-model="newUser.email"
                    focused
                    />
                </div>
                <!-- Validation error -->
                <div class="input-errors" v-if="v$.newUser.email.$errors.length > 0">
                  <div class="text-red mb-2 mt--3">{{ v$.newUser.email.$errors[0].$message }}</div>
                </div>
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-lock-circle-open"></i>
                    </span>
                  </div>
                  <input
                    :placeholder="language.password"
                    type="password"
                    class="form-control input-group-alternative"
                    v-model="newUser.password"
                    />
                </div>
                <!-- Validation error -->
                <div class="input-errors" v-if="isSubmited && newUser.password && v$.newUser.password.$invalid">
                    <div class="text-red mb-4 mt--3">Password should contain at least one uppercase, one number and one special chacter</div>
                </div>
                <div class="input-errors" v-if="v$.newUser.password.$errors.length > 0">
                  <div class="text-red mb-2 mt--3">{{ v$.newUser.password.$errors[0].$message }}</div>
                </div>
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-lock-circle-open"></i>
                    </span>
                  </div>
                  <input
                    :placeholder="language.confirm_password"
                    type="password"
                    class="form-control input-group-alternative"
                    v-model="newUser.confirmPassword"
                    />
                </div>
                <!-- Validation error -->
                <div class="input-errors" v-if="v$.newUser.confirmPassword.$errors.length > 0">
                  <div class="text-red mb-2 mt--3">{{ v$.newUser.confirmPassword.$errors[0].$message }}</div>
                </div>
                <!-- Server errors -->
                <div v-if="error" class="row mt-4">
                  <div class="col-12">
                    <span class="text-danger" v-html="error"/>
                  </div>
                </div>
                <!-- Submit form button -->
                <div class="text-center">
                    <base-button type="primary" class="my-4" @click="addUser">{{language.add_user}}</base-button>
                </div>
            </form>
        </card>
    </modal>
    <!-- Modal to edit a user -->
    <modal v-model:show="editUserTab"
           v-if="editUserData.email != ''"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0">
            <div class="text-center text-muted mb-4">
                <small>{{language.edit_user_details}}</small>
            </div>
            <!-- Edit user form -->
            <form @submit.prevent>
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-email-83"></i>
                    </span>
                  </div>
                  <input
                    placeholder="Email"
                    class="form-control input-group-alternative"
                    v-model="editUserData.email"
                    focused
                    />
                </div>
                <!-- Validation error -->
                <div class="input-errors" v-if="v$.editUserData.email.$errors.length > 0">
                  <div class="text-red mb-2 mt--3">{{ v$.editUserData.email.$errors[0].$message }}</div>
                </div>
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-lock-circle-open"></i>
                    </span>
                  </div>
                  <input
                    :placeholder="language.password"
                    type="password"
                    class="form-control input-group-alternative"
                    v-model="editUserData.password"
                    />
                </div>
                <!-- Validation error -->
                <div class="input-errors" v-if="isSubmited && editUserData.password && v$.editUserData.password.$invalid">
                    <div class="text-red mb-4 mt--3">Password should contain at least one uppercase, one number and one special chacter</div>
                </div>
                <div class="input-errors" v-if="v$.editUserData.password.$errors.length > 0">
                  <div class="text-red mb-2 mt--3">{{ v$.editUserData.password.$errors[0].$message }}</div>
                </div>
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-lock-circle-open"></i>
                    </span>
                  </div>
                  <input
                    :placeholder="language.confirm_password"
                    type="password"
                    class="form-control input-group-alternative"
                    v-model="editUserData.confirmPassword"
                    />
                </div>
                <!-- Validation error -->
                <div class="input-errors" v-if="v$.editUserData.confirmPassword.$errors.length > 0">
                  <div class="text-red mb-2 mt--3">{{ v$.editUserData.confirmPassword.$errors[0].$message }}</div>
                </div>
                <!-- Server errors -->
                <div v-if="error" class="row mt-4">
                  <div class="col-12">
                    <span class="text-danger" v-html="error"/>
                  </div>
                </div>
                <!-- Submit form button -->
                <div class="text-center">
                    <base-button type="primary" class="my-4" @click="editUser">Finish</base-button>
                </div>
            </form>
        </card>
    </modal>
    <!-- Modal to delete a user -->
    <modal v-model:show="deleteUserTab"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0">
            <div class="text-center text-muted mb-4">
                <p>{{language.delete_admin_conf}}</p>
            </div>
            <div class="text-center">
                <base-button type="primary" class="my-4 mx-4" @click="deleteUser">{{language.yes}}</base-button>
                <base-button type="primary" class="my-4 mx-4" @click="deleteUserTab = false">{{language.no}}</base-button>
            </div>
        </card>
    </modal>
    <!-- Table with all the users -->
    <div class="card pb-5">
        <div class="card-header border-0">
        <div class="row align-items-center">
            <div class="col">
            <h3 class="mb-0">{{language.view_users}}</h3>
            </div>
            <div class="col text-right">
              <base-button type="primary" @click="addUserTab = true">{{language.add_user}}</base-button>
            </div>
        </div>
        </div>
        <div class="table-responsive">
        <base-table thead-classes="thead-light" :data="users" :message="language.no_users">
            <template v-slot:columns>
              <th>NO</th>
              <th>Email</th>
              <th>{{language.actions}}</th>
            </template>
            <template v-slot:default="row">
              <th scope="row">{{ row.index + 1}}</th>
              <td>{{ row.item.email }}</td>
              <td>
                <div class="text-left px-0">
                  <base-button type="primary" class="btn-sm" @click="createEditForm(row.index, row.item.id)">{{language.edit}}</base-button>
                  <base-button type="primary" class="btn-sm" @click="(deleteUserTab = true) && (selectedUser = row.item.id)">{{language.delete}}</base-button>
                </div>
              </td>
            </template>
        </base-table>
        </div>
    </div>
  </div>
</template>
<script>
import UserService from '../services/UserService'
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email, helpers } from '@vuelidate/validators'

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      users: [],
      addUserTab: false,
      editUserTab: false,
      deleteUserTab: false,
      selectedUser: 0,
      selectedId: 0,
      newUser: {
        email: '',
        password: '',
        confirmPassword: ''
      },
      editUserData: {
        email: '',
        password: '',
        confirmPassword: ''
      },
      error: '',
      language: '',
      isSubmited: false
    };
  },
  async created () {
    // get all users
    this.getAllUsers();
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  },
  methods: {
    async getAllUsers () {
      this.users = (await UserService.showAll()).data
    },
    async editUser () {
      try {
        this.isSubmited = true
        this.error = null;
        // reset form after submission
        this.v$.editUserData.$touch();
        if (this.v$.editUserData.$pending || this.v$.editUserData.$error || this.v$.editUserData.$invalid) return;

        // edit user's email and password or just the password
        if (this.editUserData.email != this.users[this.selectedId].email) {
          await UserService.editUser({
            email: this.editUserData.email,
            password: this.editUserData.password
          }, this.selectedUser);
        } else {
          await UserService.editUser({
            password: this.editUserData.password
          }, this.selectedUser);
        }
        this.isSubmited = false
        this.editUserTab = false
        this.$swal('Updated', 'User edited successfully!', 'succes');
        this.getAllUsers()
      } catch (error) {
        // save server error
        if (error.response.status == 400) {
          this.error = error.response.data.message
        } else {
          this.$swal('Error', 'Sorry! An error has occured.', 'error');
        }
      }
    },
    async deleteUser () {
      try {
        // delete user from database
        await UserService.deleteUser(this.selectedUser)
        // delete user from local users 
        this.getAllUsers()
        this.deleteUserTab = false;
        this.selectedUser = null;
        this.$swal('Deleted', 'User deleted successfully!', 'success');
      } catch (error) {
        console.log(error);
        this.$swal('Error', 'Sorry! An error has occured.', 'error');
      }
    },
    async addUser () {
      try {
        this.error = null;
        this.isSubmited = true;
        // reset form after submission
        this.v$.newUser.$touch();
        if (this.v$.newUser.$pending || this.v$.newUser.$error || this.v$.newUser.$invalid) return;

        // add new user
        await UserService.newUser(this.newUser);
        this.addUserTab = false;
        this.getAllUsers()
        this.isSubmited = false;
        this.$swal('Saved', 'User added successfully!', 'success');
      } catch (error) {
        if (error.response.status == 400) {
          this.error = error.response.data.message
        } else {
          this.$swal('Error', 'Sorry! An error has occured.', 'error');
        }
      }
    },
    createEditForm (id, selectedIndex) {
      this.editUserData.email = this.users[id].email;
      this.editUserTab = true;
      this.selectedId = id;
      this.selectedUser = selectedIndex;
    }
  },
  // validations for add and edit user forms
  validations() {
    return {
      newUser: {
        email: { 
          required: helpers.withMessage(
            () => 'Email is required',
            required
          ),
          email: helpers.withMessage(
            () => 'You have entered an invalid email address.',
            email
          )
        },
        password: { 
          required: helpers.withMessage(
            () => 'Password is required',
            required
          ),
          valid: function(value) {
            const containsUppercase = /[A-Z]/.test(value)
            const containsSpecial = /[#?!@$%^&*-]/.test(value)
            const containsNumber = /[0-9]/.test(value)
            return containsUppercase && containsSpecial && containsNumber
          },
          min: helpers.withMessage(
            ({
              $params
            }) => `Password must have a min length of ${$params.min} characters`,
            minLength(8)
          ),
          max: helpers.withMessage(
            ({
              $params
            }) => `Password must have a max length of ${$params.max} characters`,
            maxLength(32)
          ),
        },
        confirmPassword: {
          required: helpers.withMessage(
            () => 'Password confirmation is required',
            required
          ),
          samePassword: {
            $validator: samePass,
            $message: `Password doesn't match`
          }
        }
      },
      editUserData: {
        email: { 
          required: helpers.withMessage(
            () => 'Email is required',
            required
          ),
          email: helpers.withMessage(
            () => 'You have entered an invalid email address.',
            email
          )
        },
        password: { 
          required: helpers.withMessage(
            () => 'Password is required',
            required
          ),
          valid: function(value) {
            const containsUppercase = /[A-Z]/.test(value)
            const containsSpecial = /[#?!@$%^&*-]/.test(value)
            const containsNumber = /[0-9]/.test(value)
            return containsUppercase && containsSpecial && containsNumber
          },
          min: helpers.withMessage(
            ({
              $params
            }) => `Password must have a min length of ${$params.min} characters`,
            minLength(8)
          ),
          max: helpers.withMessage(
            ({
              $params
            }) => `Password must have a max length of ${$params.max} characters`,
            maxLength(32)
          ),
        },
        confirmPassword: {
          required: helpers.withMessage(
            () => 'Password confirmation is required',
            required
          ),
          samePassword2: {
            $validator: samePass2,
            $message: `Password doesn't match`
          }
        }
      },
    }
  },
};

export function samePass(pass) {
  if (pass === '') {
    return false;
  }
  return pass === this.newUser.password;
}

export function samePass2(pass) {
  if (pass === '') {
    return false;
  }
  return pass === this.editUserData.password;
}

</script>