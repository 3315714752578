<template>
  <div>
    <reports-cards
      :lastMonth="lastMonth"
      :currentMonth="currentMonth"
      :oldDataLoaded="oldDataLoaded"
      :language="language"/>

    <div class="container-fluid mt--7">
      <top-products-table :topProducts="topProducts" :language="language"/>

      <div v-if="isAdmin == 1" class="row mt-5">
        <product-performance-table :language="language"/>
      </div>

      <div v-if="isAdmin == 1" class="row mt-5">
        <recommendations-table
          :fingerprints="fingerprints"
          :users="users"
          :deliveredPopups="currentMonth.deliveredPopups"
          :language="language"/>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import PopupService from '../services/PopupService';
import ReportsCards from '../components/Custom/ReportsCards.vue';
import TopProductsTable from '../components/Custom/TopProductsTable.vue';
import ProductPerformanceTable from '../components/Custom/ProductPerformanceTable.vue';
import RecommendationsTable from '../components/Custom/RecommendationsTable.vue';

export default {
  components: {
    ReportsCards,
    TopProductsTable,
    ProductPerformanceTable,
    RecommendationsTable 
  },
  data() {
    return {
      topProducts: [],
      currentMonth: {
        guestsNumber: 0,
        sessionsNumber: 0,
        actionsPerSession: 0,
        averageSessionDuration: 0,
        cartViews: 0,
        deliveredPopups: [],
        dismissedPopups: 0,
        accessedProducts: 0,
        discountsViews: 0,
      },
      lastMonth: {
        guestsNumber: 0,
        sessionsNumber: 0,
        actionsPerSession: 0,
        averageSessionDuration: 0,
        cartViews: 0,
        deliveredPopups: 0,
        dismissedPopups: 0,
        accessedProducts: 0,
        discountsViews: 0 
      },
      oldDataLoaded: false,
      language: '',
      isAdmin: 0,
      users: [],
      fingerprints: [],
    };
  },
  beforeCreate () {
    // Redirect to login page if user is not logged in 
    if (this.$store.state.isUserLoggedIn == false || this.$store.state.isVerified == false) {
      this.$router.push({ name: 'login' })
    }
  },
  async created () {
    this.isAdmin = JSON.parse(sessionStorage.getItem('isAdmin'));
    const current_date = new Date()
    
    this.getOldStatistics(current_date);
    // get statistics every 15 min
    this.getStatisticsData(current_date);

    window.setInterval(() => {
      // init all values
      this.currentMonth.guestsNumber = 0
      this.currentMonth.sessionsNumber = 0
      this.currentMonth.actionsPerSession = 0
      this.currentMonth.averageSessionDuration = 0
      this.currentMonth.cartViews = 0
      this.currentMonth.deliveredPopups = []
      this.currentMonth.dismissedPopups = 0
      this.currentMonth.accessedProducts = 0
      this.currentMonth.discountsViews = 0
      this.getStatisticsData(current_date)
    }, 60000)

    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  },
  methods: {
    async getStatisticsData (current_date) {
      let actions = 0;
      let session_duration = 0;

      // get statistics from Data Collector
      // ${this.$store.state.user.authorization_token}
      const jsonData = (await axios.get('https://statsapi.smartbusiness.digital/web/statistics/', {
        headers: {
          Authorization: `Token ${this.$store.state.user.authorization_token}`
        }
      })).data[0]
      
      this.fingerprints = Object.keys(jsonData.data.users);

      this.users = jsonData.data.users;

      // get monthly sold products
      if (Object.keys(jsonData.data.users).length > 0) {
        Object.keys(jsonData.data.users).forEach((username) => {
          const user = jsonData.data.users[username];
          const register_date = new Date(user.register_date);

          // current month new users
          if (register_date.getMonth() == current_date.getMonth()) {
            this.currentMonth.guestsNumber++;
          }

          // add the number of sessions
          this.currentMonth.sessionsNumber += user.sessions;

          // add the actions per session
          actions += user.actions_per_session;

          // add session duration
          session_duration += user.session_duration;

          // add the number of cart views
          this.currentMonth.cartViews += user.cart_views;
        })
        
        // averange actions per session
        this.currentMonth.actionsPerSession = (actions/(Object.keys(jsonData.data.users).length)).toFixed(2);

        // average session duration
        this.currentMonth.averageSessionDuration = (session_duration/(Object.keys(jsonData.data.users).length)).toFixed(2);
      }

      // get top products
      this.topProducts = jsonData.data.overall.top_products

      // get popup statistics
      this.getPopupsData(current_date)
    },
    async getPopupsData (current_date) {
      // get popups data
      const popups = (await PopupService.getHistory()).data.data
      let users = [];
      this.currentMonth.deliveredPopups = popups;

      popups.forEach((popup) => {
        if (popup.dismissed == 1) {
          this.currentMonth.dismissedPopups++
        }
        if (popup.products_clicked != "") {
          this.currentMonth.accessedProducts++
        }
        if (popup.discount_code_viewed == 1) {
          this.currentMonth.discountsViews++
        }
        if (users.indexOf(popup.client_fingerprint) === -1) {
          if (!(popup.discount_code != null && popup.discount_code != "null" && popup.discount_code_viewed == false)) {
            users.push(popup.client_fingerprint)
          }
        }
      })
      
      this.fingerprints = users.filter(x => this.fingerprints.includes(x))

      // save old data in the last day of the month
      if (current_date.getDate() + 1 == '1') {
        this.updateLastMonthData();
      }
    },
    async getOldStatistics (current_date) {
      const oldStatistics = (await axios.get('https://statsapi.smartbusiness.digital/web/statistics-history/', {
        headers: {
          Authorization: `Token ${this.$store.state.user.authorization_token}`
        },
        params: {
          month: current_date.getMonth(),
          year: 1900 + current_date.getYear()
        }
      })).data[0]

      if (oldStatistics) {
        this.lastMonth = oldStatistics.data
      }

      this.oldDataLoaded = true
    },
    async updateLastMonthData () {
      let oldStatistics = {
        data: {}
      }

      oldStatistics.data = this.lastMonth
      
      await axios.post('https://statsapi.smartbusiness.digital/web/statistics-history/', JSON.stringify(oldStatistics), {
        headers: {
          Authorization: `Token ${this.$store.state.user.authorization_token}`,
          "Content-Type": "application/json"
        }
      })
    },
  },
};
</script>
<style>
  .adjust-translation {
    position: absolute;
    top: 0;
    max-width: 100% !important;
    right: 10px;
  }
</style>
