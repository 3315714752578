<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 300px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{language.products}}</h1>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary ">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{language.product_feeds}}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <div class="form-group row">
                <div class="col md-2">
                  <div class="row mx-auto">
                  <el-radio class="mx-auto" v-model="radio1" label="custom" border @change="feed_radio">Custom</el-radio>
                  <!-- <el-radio class="mx-auto" v-model="radio1" label="compari" border @change="feed_radio">Compari</el-radio> -->
                  <!-- <el-radio class="mx-auto" v-model="radio1" label="ebay" border @change="feed_radio">Ebay</el-radio> -->
                  <el-radio class="mx-auto" v-model="radio1" label="google" border @change="feed_radio">Google</el-radio>
                  </div>
                </div>
                <div class="col-xl-4 col-md-4">
                  <el-input
                    placeholder='Products Feed URL'
                    suffix-icon="el-icon-s-goods"
                    v-model="formData.feed_url">
                  </el-input>
                </div>

                <div class="col-xl-2 col-md-4">
                  <button 
                      type="button"  
                      for="product_feed"
                      class="btn btn-warning form-control wbm-height-auto" 
                      @click="import_feed">{{language.import_products}}</button>
                </div>
              </div>
            </form>

            <div class="m-2 row mx-auto" >{{ msj }}</div>
            
            <div class="row mx-auto ">
              <h1 class="mx-auto">{{language.products_table}}</h1>
            </div>
              
            <el-table
              class="table-responsive table-flush"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @header-click="headerClick"
              :data="products.slice((currentPage-1)*pageSize,currentPage*pageSize)"
              :border="true"
              :stripe="true"
              :highlight-current-row="true"
              :fit="true"
              :loading="loading">

              <el-table-column :label="language.identifier" prop="identifier" min-width="100px" align="center"/>
    
              <el-table-column :label="language.picture" min-width="100px" align="center">
                <template v-slot="scope">
                  <el-popover
                    placement="right"
                    v-bind:title=scope.row.category
                    :width="200"
                    trigger="hover"
                    v-bind:content=scope.row.name>
                      <template #reference>
                        <el-image :src=scope.row.image_url :fit=fill>
                          <template #error>
                            <div class="image-slot">
                              <el-image src="https://uxwing.com/wp-content/themes/uxwing/download/07-web-app-development/image-not-found.png" :fit=fill></el-image>
                            </div>
                          </template>
                        </el-image>
                      </template>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column :label="language.name" prop="name" min-width="150px" align="center"/>

              <el-table-column :label="language.category" prop="category" min-width="120px" align="center" :sortable="radio1 !== 'google' && radio1 !== 'ebay'"/>

              <el-table-column :label="language.subcategory" prop="subcategory" min-width="150px" align="center" :sortable="radio1 !== 'google' && radio1 !== 'ebay'"/>

              <el-table-column :label="language.subtype" prop="subtype" min-width="100px" align="center" :sortable="radio1 !== 'google' && radio1 !== 'ebay'"/>

              <el-table-column :label="language.price" prop="price" min-width="100px" align="center">
                <template v-slot="scope">
                      <span style="margin-left: 10px">{{ scope.row.price }} </span>
<!--                      <a>USD</a>-->
                  </template>
              </el-table-column>

              <el-table-column :label="language.discount" prop="discount" min-width="100px" align="center">
                <template v-slot="scope">
                      <span style="margin-left: 10px">{{ scope.row.discount }}</span>
                      <a> %</a>
                  </template>
              </el-table-column>

              <el-table-column :label="language.stock_status" prop="stock_status" min-width="120px" align="center"/>

              <el-table-column :label="language.description" type="expand" prop="description" width="100px" align="center">
                <template v-slot="scope">
                      <span style="margin-left: 10px" v-html="scope.row.description"></span>
                  </template>
              </el-table-column>

              <el-table-column :label="language.rating" prop="rating" min-width="100px" align="center"/>

              <el-table-column :label="language.product_url" prop="product_url" min-width="120px" align="center">
                <template v-slot="scope">
                      <el-popover
                        placement="left"
                        title='Product URL'
                        :width="200"
                        trigger="hover"
                        v-bind:content=scope.row.product_url>
                          <template #reference>
                            <el-link v-bind:href =scope.row.product_url icon="el-icon-discover">{{language.product_link}}</el-link>
                          </template>
                      </el-popover>
                </template>
              </el-table-column>
            </el-table>
            <div class="row mx-auto">
              <el-pagination align='center' 
                @size-change="handleSizeChange" 
                @current-change="handleCurrentChange"
                :current-page="currentPage" 
                :pager-count="3"
                :page-sizes="[1,5,10,20]" 
                :page-size="pageSize" 
                layout="total, sizes, prev, pager, next, jumper" 
                :total="products.length"
                class="mx-auto m-3 wbm-pagination-layout">
              </el-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductService from '../services/ProductService'
import UserService from '../services/UserService'

import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElPagination,
  ElPopover,
  ElButton,
  ElCollapse,
  ElInput,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  ElSelect,
  ElCollapseItem,
  ElCollapseTransition,
  ElProgress,
  ElNotification,
  ElLoading,
  ElMessage,
  ElAvatar,
  ElLink,
  ElImage
} from "element-plus";

export default {
  name: "light-table",
  components: {
    [ElButton.name]: ElButton,
    [ElPopover.name]: ElPopover,
    [ElPagination.name]: ElPagination,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElCollapse.name]: ElCollapse,
    [ElInput.name]: ElInput,
    [ElRadio.name]: ElRadio,
    [ElRadioButton.name]: ElRadioButton,
    [ElSelect.name]: ElSelect,
    [ElCollapse.name]: ElCollapse,
    [ElProgress.name]: ElProgress,
    [ElNotification.name]: ElNotification,
    [ElAvatar.name]: ElAvatar,
    [ElLink.name]: ElLink,
    [ElImage.name]: ElImage
  },
  data() {
        return {
          formData: {
            file: "",
            feed_url: "",
            currency: ""
          },
          message: "",
          theme: '',
          selected_file: "",
          products: [],
          currentPage: 1,
          total: 10,
          pageSize: 10,
          activeNames: ['1'],
          radio1: '',
          loading: true,
          currentCompany: [],
          language: '',
          selectedStore: ''
        }
      },
  filters: {
        truncate: function(data, num) {
            reqdString = ''
            for(let i=0; i<num; i++) {
                reqdString += data[i]
            }
            return reqdString;
        }
    },
  
  async mounted() {

    this.radio1 = (await UserService.getUserDetails()).data.company.feed ;
    this.feed_radio((await UserService.getUserDetails()).data.company.feed);
    this.formData.feed_url = (await UserService.getUserDetails()).data.company.feed_url;
    this.formData.currency = (await UserService.getUserDetails()).data.company.currency;
  },
  computed: {
     pagedTableData() {
       return this.tableData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
     }
   },
  methods: {

    sortChange(sortProps){
      this.headerClick(sortProps.column)
    },

    async headerClick(column){
      let loadingInstance = ElLoading.service({ fullscreen: true, text: 'Sorting products feed'});
      this.productData = (await ProductService.sort_change({column_order: column.order, column_name: column.property,
      feed_type: this.radio1})).data;
      this.products = this.productData;
      setTimeout(function() {
        loadingInstance.close();
      },200);
      
    },

    async feed_radio(label){

      if(label == 'custom'){
        
        this.selection = 2;
        this.productData = (await ProductService.products_custom()).data;
        this.products = this.productData;
        this.products_url = this.currentCompany.feed_url;
      }

      if(label == 'google'){
        this.selection = 1;
        this.productData = (await ProductService.products_google()).data;
        this.products = this.productData;
        this.products_url = this.currentCompany.feed_url;
      }
    },

    async import_feed() {
      ElNotification.info({
        title: 'Products Feed',
        message: 'Products feed is importing',
        duration: 5000
        })
        
      if(this.selection == 1){
      try {
        console.log(this.selectedStore)
          await ProductService.google_feed({url: this.formData.feed_url, storeId: this.selectedStore})
          ElNotification({
              title: 'Products Feed',
              message: 'Products successfully imported',
              type: 'success',
            })
          
        } catch (err) {
          console.log(err)
          ElNotification({
              title: 'Products Feed',
              message: 'Import error',
              type: 'error',
            })
        }
        this.productData = (await ProductService.products_google()).data;
        this.products = this.productData;
      }
      if(this.selection == 2){
        try {
          await ProductService.custom_feed({url: this.formData.feed_url,storeId: this.selectedStore})
          ElNotification({
              title: 'Products Feed',
              message: 'Products successfully imported',
              type: 'success',
            })
          
        } catch (err) {
          console.log(err)
          ElNotification({
              title: 'Products Feed',
              message: 'Import error',
              type: 'error',
            })
        }
        this.productData = (await ProductService.products_custom()).data;
        this.products = this.productData;
      }
    },
    
    handleSizeChange(val) {
        console.log(`${val} per page`);
        this.currentPage = 1;
        this.pageSize = val;
     },
    handleCurrentChange(val) {
        console.log(`Current page: ${val}`);
        this.currentPage = val;
     },
     handleChange(val) {
        console.log(val);
      },
      handleCommand(command) {
        this.$message('click on item ' + command);
      }

  },
  async created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  }
};
</script>
<style scoped>
    .succes_message{
      color: green;
    }
    .fail_message{
      color: red;
    }

    .table_custom_feed{
      display: none;
    }
    
</style>