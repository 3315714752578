import Api from '@/services/Api'

export default {
    index() {
        return Api.get('invoices');
    },

    invoice (id) {
        return Api.get(`/invoice/${id}/download`, {responseType: 'arraybuffer'});
    },

    post(data) {
        return Api.post('/invoice/create', data);
    }
}