import Api from '@/services/Api'

export default {
    // get all currencies
    indexCurrencies() {
        return Api.get('currencies');
    },

    // get all countries
    indexCountries() {
        return Api.get('countries');
    },

    // get states and cities
    indexStatesAndCities(country, state) {
        return Api.get('countries_data', {
            params: {
                country: country,
                state: state
            }
        });
    },

    indexPerformances() {
        return Api.get('product/performance');
    }
}