<template>
    <div>
        <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 300px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{language.subscription}}</h1>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary ">
             <!-- 
             <div class="row mx-auto">
                 <div class = "col mx-auto">
                     <el-card class="box-card">
                        <div slot="header" class="clearfix pb-3">
                            <div class="row">
                                <h1 class="row mx-auto">Subscription type </h1>
                            </div>
                        </div>
                            
                    </el-card>
               </div>
               <div class= "col mx-auto pb-4">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix pb-3 mx-auto">
                            <div class="row">
                                <h1 class="row mx-auto">Smart Pop-Up stats</h1>
                            </div>
                        </div>
                            <div class="row pl-3"><h3>Products sold this month through Smart Pop-up : </h3></div> 
                            <div class="row pl-3"><h3>Total value sold this month through Smart Pop-up : </h3></div> 
                            <div class="row pl-3"><h3>Total amount to be paid this month : </h3></div> 
                            <div class="row pl-3"><h3>Total amount to be paid : </h3></div> 
                    </el-card>
               </div>
               
             </div>
            
               
                
            <form  class="align-items-center" >
              
              <div class="row">
                <div class="row mx-auto">
                <el-button type="success" icon="el-icon-document" plain v-on:click="latest_invoice_download()">Download Invoice </el-button>

                <el-button disabled type="danger" icon="el-icon-money" plain>Pay Invoice</el-button>
                </div>
              </div>
              
              <h4 :class="theme">{{ message }} </h4>
            </form>
            -->
                
                
              <div class="row mx-auto">
                  
              <el-pagination align='center' 
                @size-change="handleSizeChange" 
                @current-change="handleCurrentChange"
                :current-page="currentPage" 
                :page-sizes="[1,5,10,20]" 
                :page-size="pageSize" 
                layout="total, sizes, prev, pager, next, jumper" 
                :total="invoices.length"
                class="mx-auto m-3">
              </el-pagination>
              </div>

                <div class="row">
                    <p class="mx-auto">{{language.invoice_history}}</p>
                </div>

                
               
            <div class="row col-md-12 col-l-10 col-xl-7 mx-auto">
              <el-table
                class="table-responsive table-flush "
                header-row-class-name="thead-light"
                :data="invoices.slice((currentPage-1)*pageSize,currentPage*pageSize)"
                :border="true"
                :stripe="true"
                :highlight-current-row="true"
              >
                
                <el-table-column :label="language.period" min-width="50px" align="center">
                    <template v-slot="scope">
                        <i class="el-icon-date"></i>
                        <span style="margin-left: 10px">{{ scope.row.period }}</span>
                    </template>
                </el-table-column>


                <el-table-column label="Status" min-width="50px" align="center">
                  <template v-slot="scope">
                        <span style="margin-left: 10px">{{ scope.row.status }} </span>
                    </template>
                </el-table-column>

                <el-table-column :label="language.sum"  min-width="50px" align="center">
                    <template v-slot="scope">
                        <span style="margin-left: 10px">{{ scope.row.sum }}</span>
                        <a> RON</a>
                    </template>
                </el-table-column>


                <el-table-column :label="language.actions"   min-width="100px" align="center">

                    <template v-slot="scope">
                        <el-button type="success" icon="el-icon-document" plain v-on:click="invoice_download(id = scope.row.id, invoice = scope.row.period, )">{{language.download_invoice}}</el-button>
                    </template>
                </el-table-column>

                
      
              </el-table>
              </div>
          </card>
        </div>
      </div>
    </div>
    </div>

</template>

<script>
import PayService from '../services/PayService'

import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElPagination,
  ElButton,
  ElCard
} from "element-plus";

export default {
  name: "light-table",
  components: {
    [ElCard.name]: ElCard,
    [ElButton.name]: ElButton,
    [ElPagination.name]: ElPagination,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu
  },
  data() {
        return {
          formData: {
            file: "",
          },
          message: "",
          theme: '',
          selected_file: "",
          invoices: [],
          currentPage: 1,
          total: 10,
          pageSize: 10,
          language: ''
        }
      },
  
  async mounted() {
    this.invoiceData = (await PayService.index()).data;
    this.invoices = this.invoiceData;
    console.log(this.invoiceData);
  },
  created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  },
  computed: {
     pagedTableData() {
       return this.tableData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

     }
   },
  methods: {
    async invoice_download(id, invoice){
        this.invoicePDF = (await PayService.invoice(id)).data;
        var fileURL = window.URL.createObjectURL(new Blob([this.invoicePDF]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "invoice "+invoice+".pdf");
        document.body.appendChild(fileLink);
        fileLink.click();  
    },
    async latest_invoice_download(){
        this.invoicePDF = (await PayService.latest_invoice()).data;
        var fileURL = window.URL.createObjectURL(new Blob([this.invoicePDF]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "invoice "+invoice+".pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
    },
    handleSizeChange(val) {
        console.log(`${val} per page`);
        this.currentPage = 1;
        this.pageSize = val;
     },
    handleCurrentChange(val) {
        console.log(`Current page: ${val}`);
        this.currentPage = val;
     }

  },
};
</script>
<style scoped>
    .succes_message{
      color: green;
    }
    .fail_message{
      color: red;
    }
</style>
