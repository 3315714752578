<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted">
            <!-- Success message -->
            <h2 v-if="!error">Thank you!</h2>
            <h2 v-if="error">We are sorry!</h2>
            <p>{{ message }}</p>
          </div>
          <div v-if="!error" class="text-center">
            <button type="submit" class="btn btn-primary my-1" @click="login">GO TO DASHBOARD</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService'
import UserService from '../services/UserService'

export default {
  data() {
    return {
      message: '',
      error: false
    };
  },
  async created () {
    try {
      // confirm account
      const response = await AuthService.confirm(this.$route.params.token)
      // save server errors
      if (response.status == 200) {
        this.message = response.data.message;
      } else {
        this.message = response.data.message;
        this.error = true;
      }
      
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    // push login route
    async login () {
      this.$store.dispatch('setUserToken', this.$route.query.token)

      const response = (await UserService.index()).data

      this.$store.dispatch('setUser', response)
      this.$store.dispatch('setVerification', true);

      this.$store.dispatch("fetchUserConditions", { self: this })

      this.$router.push({name: "installation"})
    }
  }
};
</script>

<style></style>
