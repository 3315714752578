<template>
    <div class="col-xl-12 mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">{{language.product_performance}}</h5>
                </div>
              </div>
            </template>
            <div class="table-responsive">
              <base-table thead-classes="thead-light" :data="performances" :message="language.no_products_message">
                <template v-slot:columns>
                  <th>{{language.product_name}}</th>
                  <th>{{language.history_average }}</th>
                  <th>{{language.current_average}}</th>
                </template>
                
                <template v-slot:default="row">
                  <td>
                    <a :href="row.item.product_url" class="wbm-product-link" target=”_blank”>{{ row.item.name }}</a>
                  </td>
                  <td>{{ row.item.history_average }}</td>
                  <td>{{ row.item.current_average }}</td>
                </template>
              </base-table>
            </div>
        </card>
    </div>
</template>

<script>
import HelpersService from '../../services/HelpersService';

export default {
    name: "product-performance-table",
    props: [
        'language'
    ],
    data () {
      return {
        performances: []
      }
    },
    mounted () {
      this.getProductsPerformance()
    },
    methods: {
      async getProductsPerformance () {
        this.performances = (await HelpersService.indexPerformances()).data.products;
      }
    }

}
</script>