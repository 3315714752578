<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 300px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{language.profile}}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{language.profile_information}}</h3>
                  </div>
                </div>
              </div>
            </template>
            <!-- Company informations form -->
            <form @submit.prevent>
              <h6 class="heading-small text-muted mb-4">{{language.company_informations}}</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.country}} *
                      </label>
                    </slot>
                    <vue-select
                      :options="$store.state.countries"
                      v-model="formData.country"
                      :placeholder="formData.country || language.country"
                      close-on-select
                      searchable
                      clear-on-select
                      :loading="loadCountries"> </vue-select>
                    <div class="input-errors" v-for="(error, index) of v$.formData.country.$errors" :key="index">
                      <div class="text-red mt--3 mb-2">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.company_name}} *
                      </label>
                    </slot>
                    <div class="form-group input-group input-group-alternative">
                      <input
                        :placeholder="language.company_name"
                        class="form-control input-group-alternative"
                        v-model="formData.company_name"
                        focused
                        name="company_name"
                        />
                    </div>
                    <!-- Validation error -->
                    <div class="input-errors" v-for="(error, index) of v$.formData.company_name.$errors" :key="index">
                      <div class="text-red mt--3 mb-2">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div v-if="formData.country == 'Romania'" class="col-lg-12">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.registration_code}} *
                      </label>
                    </slot>
                    <div class="form-group input-group input-group-alternative">
                      <input
                        :placeholder="language.registration_code"
                        class="form-control input-group-alternative"
                        v-model="formData.registration_code"
                        focused
                        name="registration_code"
                        />
                    </div>
                    <!-- Validation error -->
                    <div class="input-errors" v-if="errorRegCode">
                      <div class="text-red mt--3 mb-2">{{ errorRegCode }}</div>
                    </div>
                  </div>
                  <div v-if="formData.country != 'Romania'" class="col-lg-12">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.vat_number}} *
                      </label>
                    </slot>
                    <div class="form-group input-group input-group-alternative">
                      <input
                        :placeholder="language.vat_number"
                        class="form-control input-group-alternative"
                        v-model="formData.vat_number"
                        focused
                        name="vat_number"
                        />
                    </div>
                    <!-- Validation error -->
                    <div class="input-errors" v-if="errorVatNumber">
                      <div class="text-red mt--3 mb-2">{{ errorVatNumber }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">{{language.office_address}}</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-4">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.county}} <span v-if="counties.length > 0">*</span>
                      </label>
                    </slot>
                    <vue-select
                      :options="counties"
                      v-model="formData.county"
                      :placeholder="formData.county || language.county"
                      close-on-select
                      searchable
                      clear-on-select> </vue-select>
                    <!-- Validation error -->
                    <div class="input-errors" v-for="(error, index) of v$.formData.county.$errors" :key="index">
                      <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.city}} <span v-if="cities.length > 0 && counties.length > 0">*</span>
                      </label>
                    </slot>
                    <vue-select
                      :options="cities"
                      v-model="formData.local"
                      :placeholder="formData.local || language.city"
                      close-on-select
                      searchable
                      clear-on-select> </vue-select>
                    <div class="input-errors" v-for="(error, index) of v$.formData.local.$errors" :key="index">
                      <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.address}} *
                      </label>
                    </slot>
                    <div class="form-group input-group input-group-alternative">
                      <input
                        :placeholder="language.address"
                        class="form-control input-group-alternative"
                        v-model="formData.address"
                        focused
                        name="address"
                        />
                    </div>
                    <!-- Validation error -->
                    <div class="input-errors" v-for="(error, index) of v$.formData.address.$errors" :key="index">
                      <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Description -->
              <h6 class="heading-small text-muted mb-4">{{language.contact_person}}</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-4">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.first_name}} *
                      </label>
                    </slot>
                    <div class="form-group input-group input-group-alternative">
                      <input
                        :placeholder="language.first_name"
                        class="form-control input-group-alternative"
                        v-model="formData.first_name"
                        focused
                        name="first_name"
                        />
                    </div>
                    <!-- Validation error -->
                    <div class="input-errors" v-for="(error, index) of v$.formData.first_name.$errors" :key="index">
                      <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.last_name}} *
                      </label>
                    </slot>
                    <div class="form-group input-group input-group-alternative">
                      <input
                        :placeholder="language.last_name"
                        class="form-control input-group-alternative"
                        v-model="formData.last_name"
                        focused
                        name="last_name"
                        />
                    </div>
                    <!-- Validation error -->
                    <div class="input-errors" v-for="(error, index) of v$.formData.last_name.$errors" :key="index">
                      <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.phone_number}} *
                      </label>
                    </slot>
                    <div class="form-group input-group input-group-alternative">
                      <input
                        :placeholder="language.phone_number"
                        class="form-control input-group-alternative"
                        v-model="formData.phone_number"
                        focused
                        name="phone_number"
                        />
                    </div>
                    <!-- Validation error -->
                    <div class="input-errors" v-for="(error, index) of v$.formData.phone_number.$errors" :key="index">
                      <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">Shop details</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.shop_url}} *
                      </label>
                    </slot>
                    <div class="form-group input-group input-group-alternative">
                      <input
                        :placeholder="language.shop_url"
                        class="form-control input-group-alternative"
                        v-model="formData.shop_details"
                        focused
                        name="shop_details"
                        />
                    </div>
                    <!-- Validation error -->
                    <div class="input-errors" v-for="(error, index) of v$.formData.shop_details.$errors" :key="index">
                      <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-6">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.currency}}
                      </label>
                    </slot>
                    <vue-select
                      :options="$store.state.currencies"
                      v-model="formData.currency"
                      :placeholder="formData.currency || language.currency"
                      close-on-select
                      searchable
                      clear-on-select> </vue-select>
                    <!-- Validation error -->
                    <div class="input-errors" v-for="(error, index) of v$.formData.currency.$errors" :key="index">
                      <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <slot name="label">
                      <label class="form-control-label">
                        {{language.product_stock_sync}} *
                      </label>
                    </slot>
                    <div class="form-group input-group input-group-alternative">
                      <input
                        placeholder="8"
                        class="form-control input-group-alternative"
                        v-model="formData.check_url_counter"
                        focused
                        name="check_url_counter"
                        />
                    </div>
                    <!-- Validation error -->
                    <div class="input-errors" v-for="(error, index) of v$.formData.check_url_counter.$errors" :key="index">
                      <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Submit form button -->
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12 text-right">
                    <button type="submit" class="btn btn-primary my-1" @click="saveData">{{language.save}}</button>
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserService from '../services/UserService'
import useVuelidate from '@vuelidate/core'
import { required, numeric, minLength, maxLength, requiredIf } from '@vuelidate/validators'
import HelpersService from '../services/HelpersService'

export default {
  name: "user-profile",
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      formData: {
        country: "",
        company_name: "",
        vat_number: "",
        registration_code: "",
        county: "",
        local: "",
        address: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        shop_details: "",
        currency: "",
        check_url_counter: ""
      },
      currentCompany: {},
      error: null,
      language: '',
      company: {},
      counties: [],
      cities: [],
      loadCountries: true,
      errorVatNumber: null, 
      errorRegCode: null
    };
  },
  created () {
    // get data about logged user's company
    this.getCompanyData()
    
    // get all currencies
    this.$store.dispatch("fetchCurrencies", { self: this })

    // get all countries
    this.$store.dispatch("fetchCountries", { self: this })
    this.loadCountries = false;

    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  },
  methods: {
    async getCompanyData () {
      this.company = (await UserService.getUserDetails()).data.company

      if (this.company !== null) {
        this.formData = this.company
      }
    },
    async saveData () {
      // reset form after submission
      this.errorVatNumber = ""
      this.errorRegCode = ""

      if (!this.validVatNumber(this.formData.vat_number)) {
        this.errorVatNumber = "Invalid VAT number"
        return
      }

      if (!this.validRegCode((this.formData.registration_code))) {
        this.errorRegCode = "Invalid Registration code"
        return
      }

      this.v$.formData.$touch();
      if (this.v$.formData.$pending || this.v$.formData.$error) return;

      // create or update informations about company
      if (this.company === null) {
        try {
          await UserService.addUserDetails(this.formData)
          this.$store.dispatch('setDataCompleted', true)
          this.getCompanyData()
          this.$swal('Saved', 'Profile informations saved successfully!', 'success')
        } catch (err) {
          console.log(err)
          this.$swal('Error', 'Sorry! An error has occured.', 'error')
        }
      } else {
        try {
          await UserService.updateUserData(this.formData, this.company.id)
          this.getCompanyData()
          this.$swal('Updated', 'Profile informations updated successfully!', 'success')
        } catch (err) {
          console.log(err);
          this.$swal('Error', 'Sorry! An error has occured.', 'error')
        }
      }
      
    },
    // get counties after the country is selected
    async getCounties (country, oldCountry) {
      if (country) {
        this.counties = (await HelpersService.indexStatesAndCities(country)).data.states
        this.counties = this.counties.map(county => county.name)
        this.cities = []
        if (oldCountry != "") {
          this.formData.county = ""
          this.formData.local = ""
        }
      }
    },
    // get city after the country and the county is selected
    async getCities (county, oldCounty) {
      if (county) {
        this.cities = (await HelpersService.indexStatesAndCities(this.formData.country, county)).data.cities
        this.cities = this.cities.map(city => city.name)
        if (oldCounty != "") {
          this.formData.local = ""
        }
      }
    },
    // validation function for VAT number
    validVatNumber (number) {
      if ((number === null || number === "") && this.formData.country == 'Romania') {
        return true;
      } else if ((number === null || number === "") && this.formData.country != 'Romania') {
        return false;
      }
      return true;
    },
    // validation function for Registration Code
    validRegCode (number) {
      if ((number === null || number === "") && this.formData.country == 'Romania') {
        return false;
      } else if ((number === null || number === "") && this.formData.country != 'Romania') {
        return true;
      }
      if (isNaN(number.substring(0,1)) == false) {
        let pattern = new RegExp(`^[0-9]{1,10}$`);
        return pattern.test(number);
      } else if (number.substring(0,2) == 'RO') {
        let pattern = new RegExp(`^[0-9]{1,10}$`);
        return pattern.test(number.substring(2, number.length));
      } else {
        return false;
      }
    }
  },
  watch: {
    'formData.country': function (value, oldValue) {
      this.getCounties(value, oldValue);
    },
  },
  // validations for company informations form
  validations() {
    return {
      formData: {
        country: { required },
        county: {
          required: requiredIf(function () {
            return this.counties.length > 0
          })
        },
        local: { 
          required: requiredIf(function () {
            return this.cities.length > 0
          })
        },
        company_name: { required, min: minLength(5), max: maxLength(50) },
        address: { required, min: minLength(10), max: maxLength(50) },
        first_name: { required, min: minLength(3), max: maxLength(50) },
        last_name: { required, min: minLength(3), max: maxLength(50) },
        phone_number: { required, numeric},
        shop_details: { required, max: maxLength(60) },
        currency: { required },
        check_url_counter: { required, numeric }
      }
    }
  },
};

</script>
