import Api from '@/services/Api'

export default {
    // register user
    register (credentials) {
        return Api.post('register', credentials);
    },

    // login user
    login (credentials) {
        return Api.post('login', credentials);
    },

    // logout user
    logout () {
        return Api.post('logout');
    },

    // send reset password email
    forgotPassword (email) {
        return Api.post('forgotten-password', email);
    },

    // reset password
    reset (token, password, confirmPassword) {
        return Api.post(`/reset-password/${token}`, {
            password: password,
            password_confirmation: confirmPassword
        })
    },

    // verify account token
    confirm (confirmToken) {
        return Api.post(`verify-email/${confirmToken}`);
    }
}