<template>
    <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <card header-classes="bg-transparent">

            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">{{language.top_products}}</h5>
                </div>
              </div>
            </template>

            <div class="table-responsive">
              <base-table thead-classes="thead-light" :data="topProducts" :message="language.no_products_message">
                <template v-slot:columns>
                  <th>{{language.product_url}}</th>
                  <th>{{language.number_of_visits}}</th>
                </template>

                <template v-slot:default="row">
                  <th scope="row">
                    <a :href="row.item[0]" target=”_blank”>
                      {{ row.item[0] }}
                    </a>
                  </th>
                  <td>
                      {{ row.item[1] }}
                  </td>
                </template>
              </base-table>
            </div>
            
          </card>
        </div>
      </div>
</template>

<script>
export default {
    name: "top-products-table",
    props: [
        'topProducts',
        'language'
    ]
}
</script>