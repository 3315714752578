<template>
  <div class="main-content bg-default">
    <div class="align-items-center vh-100">
        <!-- Page content -->
        <div class="container vh-100 pt-9">
            <router-view></router-view>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "auth-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
      language: ''
    };
  },
  created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  }
};
</script>
<style>
.footer-container {
  height: 22vh;
  text-align: center;
}
.translation-container{
  margin-top: 8px;
}
</style>
