import Api from '@/services/Api';

export default {
    async saveStore(data) {
        return Api.post('/save-store', data);
    },
    async getStores() {
        return Api.get('/get-stores');
    },
    async getUserStores() {
        return Api.get('/get-user-stores');
    },
    async updateUserStore(data) {
        return Api.post('/update-user-store', data);
    },
    async deleteUserStore(data) {
        return Api.post('/delete-user-store', data);
    },
    async importProducts(data) {
        return Api.post('/retail/import', data);
    }
}