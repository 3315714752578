import Api from '@/services/Api'

export default {
    // add popup configuration
    addConfig (data) {
        return Api.post('popup', data);
    },
    // edit popup configuration
    updateConfig (data, id) {
        return Api.post(`popup/edit/${id}`, data);
    },
    // get popup data
    getPopup () {
        return Api.get('popup');
    },
    // delete introduction messages from popup
    deleteIntroMessages (idsIntroductionMessages) {
        return Api.delete('introduction_messages', {
            data: {
                idsIntroductionMessages: idsIntroductionMessages
            }
        });
    },
    // delete biases from popup
    deleteBiases (idsBiases) {
        return Api.delete('bias', {
            data: {
                idsBiases: idsBiases
            }
        });
    },
    // get history about popups
    getHistory () {
        return Api.get('popups-history-data');
    }
}