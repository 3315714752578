<template>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="container-fluid d-flex align-items-center mb-4">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{language.reports}}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- New guests -->
        <div class="col-xl-4 col-lg-4">
          <stats-card
            :title="language.new_guests"
            type="gradient-red"
            :sub-title="currentMonth.guestsNumber.toString()"
            icon="ni ni-single-02"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div v-if="oldDataLoaded && calculatePercentage(lastMonth.guestsNumber, currentMonth.guestsNumber) != 0">
                <span :class="adjustPercentageColor(lastMonth.guestsNumber, currentMonth.guestsNumber)">
                  <i :class="adjustPercentageArrow(lastMonth.guestsNumber, currentMonth.guestsNumber)"></i> {{ calculatePercentage(lastMonth.guestsNumber, currentMonth.guestsNumber) }} %
                </span>
                <span class="text-nowrap">{{language.since_last_month}}</span>
              </div>
            </template>
          </stats-card>
        </div>
        <!-- Visits -->
        <div class="col-xl-4 col-lg-4">
          <stats-card
            :title="language.visits"
            type="gradient-blue"
            :sub-title="currentMonth.sessionsNumber.toString()"
            icon="ni ni-shop"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div v-if="oldDataLoaded && calculatePercentage(lastMonth.sessionsNumber, currentMonth.sessionsNumber) != 0">
                <span :class="adjustPercentageColor(lastMonth.sessionsNumber, currentMonth.sessionsNumber)">
                  <i :class="adjustPercentageArrow(lastMonth.sessionsNumber, currentMonth.sessionsNumber)"></i> {{ calculatePercentage(lastMonth.sessionsNumber, currentMonth.sessionsNumber) }} %
                </span>
                <span class="text-nowrap">{{language.since_last_month}}</span>
              </div>
            </template>
          </stats-card>
        </div>
        <!-- Actions per visit -->
        <div class="col-xl-4 col-lg-4">
          <stats-card
            :title="language.actions_per_visit"
            type="gradient-green"
            :sub-title="currentMonth.actionsPerSession.toString()"
            icon="ni ni-laptop"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div v-if="oldDataLoaded && calculatePercentage(lastMonth.actionsPerSession, currentMonth.actionsPerSession) != 0">
                <span :class="adjustPercentageColor(lastMonth.actionsPerSession, currentMonth.actionsPerSession)">
                  <i :class="adjustPercentageArrow(lastMonth.actionsPerSession, currentMonth.actionsPerSession)"></i> {{ calculatePercentage(lastMonth.actionsPerSession, currentMonth.actionsPerSession) }} %
                </span>
                <span class="text-nowrap">{{language.since_last_month}}</span>
              </div>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row mt-md-4">
        <!-- Average visit duration -->
        <div class="col-xl-4 col-lg-4">
          <stats-card
            :title="language.average_visit_duration"
            type="gradient-purple"
            :sub-title="currentMonth.averageSessionDuration.toString() + 's'"
            icon="ni ni-time-alarm"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div v-if="oldDataLoaded && calculatePercentage(lastMonth.averageSessionDuration, currentMonth.averageSessionDuration) != 0">
                <span :class="adjustPercentageColor(lastMonth.averageSessionDuration, currentMonth.averageSessionDuration)">
                  <i :class="adjustPercentageArrow(lastMonth.averageSessionDuration, currentMonth.averageSessionDuration)"></i> {{ calculatePercentage(lastMonth.averageSessionDuration, currentMonth.averageSessionDuration) }} %
                </span>
                <span class="text-nowrap">{{language.since_last_month}}</span>
              </div>
            </template>
          </stats-card>
        </div>
        <!-- Cart views -->
        <div class="col-xl-4 col-lg-4">
          <stats-card
            :title="language.cart_views"
            type="gradient-pink"
            :sub-title="currentMonth.cartViews.toString()"
            icon="ni ni-cart"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div v-if="oldDataLoaded && calculatePercentage(lastMonth.cartViews, currentMonth.cartViews) != 0">
                <span :class="adjustPercentageColor(lastMonth.cartViews, currentMonth.cartViews)">
                  <i :class="adjustPercentageArrow(lastMonth.cartViews, currentMonth.cartViews)"></i> {{ calculatePercentage(lastMonth.cartViews, currentMonth.cartViews) }} %
                </span>
                <span class="text-nowrap">{{language.since_last_month}}</span>
              </div>
            </template>
          </stats-card>
        </div>
        <!-- Delivered popups -->
        <div class="col-xl-4 col-lg-4">
          <stats-card
            :title="language.delivered_popups"
            type="gradient-indigo"
            :sub-title="currentMonth.deliveredPopups.length.toString()"
            icon="ni ni-check-bold"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div v-if="oldDataLoaded && calculatePercentage(lastMonth.deliveredPopups, currentMonth.deliveredPopups.length) != 0">
                <span :class="adjustPercentageColor(lastMonth.deliveredPopups.length, currentMonth.deliveredPopups.length)">
                  <i :class="adjustPercentageArrow(lastMonth.deliveredPopups.length, currentMonth.deliveredPopups.length)"></i> {{ calculatePercentage(lastMonth.deliveredPopups, currentMonth.deliveredPopups.length) }} %
                </span>
                <span class="text-nowrap">{{language.since_last_month}}</span>
              </div>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row mt-md-4">
        <!-- Dismissed popups -->
        <div class="col-xl-4 col-lg-4">
          <stats-card
            :title="language.dismissed_popups"
            type="gradient-teal"
            :sub-title="currentMonth.dismissedPopups.toString()"
            icon="ni ni-curved-next"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div v-if="oldDataLoaded && calculatePercentage(lastMonth.dismissedPopups, currentMonth.dismissedPopups) != 0">
                <span :class="adjustPercentageColor(lastMonth.dismissedPopups, currentMonth.dismissedPopups)">
                  <i :class="adjustPercentageArrow(lastMonth.dismissedPopups, currentMonth.dismissedPopups)"></i> {{ calculatePercentage(lastMonth.dismissedPopups, currentMonth.dismissedPopups) }} %
                </span>
                <span class="text-nowrap">{{language.since_last_month}}</span>
              </div>
            </template>
          </stats-card>
        </div>
        <!-- Accessed products -->
        <div class="col-xl-4 col-lg-4">
          <stats-card
            :title="language.accessed_products"
            type="gradient-red"
            :sub-title="currentMonth.accessedProducts.toString()"
            icon="ni ni-credit-card"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div v-if="oldDataLoaded && calculatePercentage(lastMonth.accessedProducts, currentMonth.accessedProducts) != 0">
                <span :class="adjustPercentageColor(lastMonth.accessedProducts, currentMonth.accessedProducts)">
                  <i :class="adjustPercentageArrow(lastMonth.accessedProducts, currentMonth.accessedProducts)"></i> {{ calculatePercentage(lastMonth.accessedProducts, currentMonth.currentMonth.accessedProducts) }} %
                </span>
                <span class="text-nowrap">{{language.since_last_month}}</span>
              </div>
            </template>
          </stats-card>
        </div>
        <!-- Discounts views -->
        <div class="col-xl-4 col-lg-4">
          <stats-card
            :title="language.discount_views"
            type="gradient-green"
            :sub-title="currentMonth.discountsViews.toString()"
            icon="ni ni-tag"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div  v-if="oldDataLoaded && calculatePercentage(lastMonth.discountsViews, currentMonth.discountsViews) != 0">
                <span :class="adjustPercentageColor(lastMonth.discountsViews, currentMonth.discountsViews)">
                  <i :class="adjustPercentageArrow(lastMonth.discountsViews, currentMonth.discountsViews)"></i> {{ calculatePercentage(lastMonth.discountsViews, currentMonth.discountsViews) }} %
                </span>
                <span class="text-nowrap">{{language.since_last_month}}</span>
              </div>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>
</template>

<script>
export default {
    name: "reports-cards",
    props: [
        'lastMonth',
        'currentMonth',
        'oldDataLoaded',
        'language'
    ],
    methods: {
        calculatePercentage (oldValue, newValue) {
            if (oldValue !== 0) {
                return ((newValue - oldValue) * 100 / oldValue).toFixed(2);
            } else {
                return 0;
            } 
        },
        adjustPercentageColor (oldValue, newValue) {
            return {
                'text-success mr-2': this.calculatePercentage(oldValue, newValue) >= 0,
                'text-danger mr-2': this.calculatePercentage(oldValue, newValue) < 0
            }
        },
        adjustPercentageArrow (oldValue, newValue) {
            return {
                'fa fa-arrow-up': this.calculatePercentage(oldValue, newValue) >= 0,
                'fa fa-arrow-down': this.calculatePercentage(oldValue, newValue) < 0
            }
        },
    }
}
</script>