<template>
  <div class="bg-default vh-100 pt-9 justify-content-center">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6">
        <div class="card bg-secondary shadow border-0">
          <div class="card-body px-lg-5 py-lg-5">
<!--            <translation></translation>-->
            <div class="text-center mb-4">
              <h3>{{language.choose_client}}:</h3>
              <div class="row justify-content-center pt-4">
                <div class="col-md-6">
                  <vue-select
                    :options="clients"
                    v-model="selectedClient"
                    label-by="client"
                    :placeholder="language.shop_name"
                    close-on-select
                    searchable
                    clear-on-select> </vue-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary my-1" @click="login">{{language.view_dashboard}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from '../../services/AdminService'
import Translation from '../../components/Translation';
import UserService from "../../services/UserService";

export default {
  components: {Translation},
  data() {
    return {
      clients: [],
      selectedClient: {},
      language: ''
    };
  },
  beforeCreate () {
    // Redirect to login page if admin is not logged in 
    const isAdmin = JSON.parse(sessionStorage.getItem('isAdmin'));
    if (isAdmin != 1) {
      this.$router.push({ name: 'login' })
    }
  },
  async created () {
    const isAdmin = JSON.parse(sessionStorage.getItem('isAdmin'));
    // get all companies
    if(isAdmin === 1) { 
      let companies = await UserService.getCompanies();
      this.clients = companies.data.companies;
    }

    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  },
  methods: {
    async login () {
      try {
        // send login credentials
        const response = await AdminService.login(this.selectedClient.company_id);

        // set the store token and logged in user
        this.$store.dispatch('setUserToken', response.data.token)
        this.$store.dispatch('setUser', response.data.user)

        // if the user has his profile data completed set the local storage
        if (response.data.user.is_complete == 1) {
          this.$store.dispatch('setDataCompleted', true);
        } else {
          this.$store.dispatch('setDataCompleted', false);
        }

        // check if products are loaded and popup is configured
        this.$store.dispatch("fetchUserConditions", { self: this })
        this.$store.dispatch('setVerification', true);
        this.$router.push({
          name: 'reports'
        })
      } catch (error) {
        if (error.response.status == 404) {
          this.error = error.response.data.message
        } else if (error.response.status == 422) {
          this.error = error.response.data
        }
      }
    }
  }
};
</script>
<style></style>
