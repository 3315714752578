import Api from '@/services/Api'

export default {
    // get all links
    index () {
        return Api.get('links');
    },
    // create links
    create (links) {
        return Api.post('links/create', {}, {
            params: {
                company_url: links.get('company_url'),
                cart_url: links.get('cart_url'),
                checkout_url: links.get('checkout_url'),
                product_url: links.get('product_url'),
                success_order_url: links.get('success_order_url')
            }
        });
    },
    // edit links
    update (links) {
        return Api.put('links/update', {}, {
            params: {
                company_url: links.get('company_url'),
                cart_url: links.get('cart_url'),
                checkout_url: links.get('checkout_url'),
                product_url: links.get('product_url'),
                success_order_url: links.get('success_order_url')
            }
        });
    },
}