import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import { sync } from 'vuex-router-sync';
import store from '@/store/store';
import VueSelect from 'vue-next-select'
import '@/assets/css/vue-select.min.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import GAuth from 'vue3-google-oauth2'
import Hotjar from 'vue-hotjar'


const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(store);
appInstance.config.globalProperties.$store=store;
appInstance.use(ArgonDashboard);
appInstance.component('vue-select', VueSelect)
appInstance.use(VueSweetalert2);

if (process.env.VUE_APP_MODE === 'production') {
    appInstance.use(Hotjar, {
        id: '2737229',
        isProduction: true,
        snippetVersion: 6
    });
}

sync(store, router);

let userLanguage;
localStorage.setItem('language', 'en');
userLanguage = localStorage.getItem('language');

import('./language/' + userLanguage).then(function(module) {
    localStorage.setItem('language_variable', JSON.stringify(module.language));
    store.dispatch('setLanguage', module.language);
});


const gAuthOptions = { clientId: '353916847966-3mq96giepa4mptg82nk6nsd4k73hst78.apps.googleusercontent.com', scope: 'https://www.googleapis.com/auth/content', prompt: 'consent', fetch_basic_profile: false }
appInstance.use(GAuth, gAuthOptions);

appInstance.mount("#app");