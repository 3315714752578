import Api from '@/services/Api'

export default {
    // get logged user data
    index () {
        return Api.get('user');
    },
    updateUserData (data, id) {
        return Api.put(`company/edit/${id}`, data);
    },
    // get company data
    getUserDetails () {
        return Api.get('company');
    },
    // create company 
    addUserDetails (data) {
        return Api.post('company/create', data);
    },
    // show all admin users
    showAll () {
        return Api.get('users');
    },
    // create a new admin user
    newUser (user) {
        return Api.post('user/create', user);
    },
    // edit admin user data
    editUser (user, id) {
        return Api.put(`user/update/${id}`, user);
    },
    // delete admin user
    deleteUser (id) {
        return Api.delete(`user/delete/${id}`);
    },
    // check if user has configured his popup and loaded products
    checkUser () {
        return Api.get('check-user');
    },
    //get companies for admin user
    async getCompanies() {
        return Api.get('/get-clients');
    }

}