import axios from 'axios'
import store from '@/store/store'
import router from '../router'

const axiosRequest = axios.create({
    baseURL:  'https://api.platforma-smart.agentiawebmagnat.ro/api/'
});

axiosRequest.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${store.state.token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosRequest.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response.status === 401) {
        // redirect to login page 
        router.push({
            name: 'login'
        })
    }
    return Promise.reject(error);
});


export default axiosRequest;
