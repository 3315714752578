<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <!-- Success message -->
        <div v-if="reset" class="card-body px-lg-5 py-lg-5">
          <div class="text-center mb-2">
            <h3>Password changed!</h3>
            <p>Your password has been changed successfully.</p>
          </div>
          <div v-if="reset" class="text-center">
            <button type="submit" class="btn btn-primary my-1" @click="login">Login</button>
          </div>
        </div>
        <div v-if="!reset" class="card-body px-lg-5 py-lg-5">
          <div class="text-center mb-4">
            <h4>Reset your password</h4>
          </div>
          <!-- Reset password form -->
          <form @submit.prevent>
            <div class="form-group input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-lock-circle-open"></i>
                </span>
              </div>
              <input
                placeholder="Password"
                type="password"
                class="form-control input-group-alternative"
                v-model="newPassword.password"
                />
            </div>
            <!-- Validation error -->
            
            <div class="input-errors" v-if="isSubmited && newPassword.password && v$.newPassword.password.$invalid">
                <div class="text-red mb-4 mt--3">Password should contain at least one uppercase, one number and one special chacter</div>
            </div>
            <div class="input-errors" v-if="v$.newPassword.password.$errors.length > 0">
              <div class="text-red mb-2 mt--3">{{ v$.newPassword.password.$errors[0].$message }}</div>
            </div>
            <div class="form-group input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-lock-circle-open"></i>
                </span>
              </div>
              <input
                placeholder="Confirm password"
                type="password"
                class="form-control input-group-alternative"
                v-model="newPassword.confirmPassword"
                />
            </div>
            <!-- Validation error -->
            <div class="input-errors" v-if="v$.newPassword.confirmPassword.$errors.length > 0">
              <div class="text-red mb-2 mt--3">{{ v$.newPassword.confirmPassword.$errors[0].$message }}</div>
            </div>
            <!-- Server error -->
            <div class="input-errors" v-if="error">
              <div class="text-red mb-2 mt--3">{{ error }}</div>
            </div>
            <!-- Submit form button -->
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click="resetPassword">Reset password</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/login" class="text-light">
            <small>{{language.sign_in}}</small>
          </router-link>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light">
            <small>{{language.create_new_account}}</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService'
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      newPassword: {
        password: '',
        confirmPassword: ''
      },
      error: '',
      reset: false,
      isSubmited: false,
      language: ''
    };
  },
  created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  },
  methods: {
    async resetPassword () {
      try {
        this.isSubmited = true
        this.error = '';
        // reset form after submission
        this.v$.newPassword.$touch();
        if (this.v$.newPassword.$pending || this.v$.newPassword.$error || this.v$.newPassword.$invalid) return;

        // reset password
        await AuthService.reset(this.$route.params.token, this.newPassword.password, this.newPassword.confirmPassword)

        this.reset = true
      } catch (error) {
        this.error = `Sorry! We couldn't reset your password.`
      }
    },
    login () {
      this.$router.push({name: "login"})
    }
  },
  // validations for new password form
  validations () {
    return {
      newPassword: {
        password:
        {
          required: helpers.withMessage(
            () => 'Password is required',
            required
          ),
          valid: function(value) {
            const containsUppercase = /[A-Z]/.test(value)
            const containsSpecial = /[#?!@$%^&*-]/.test(value)
            const containsNumber = /[0-9]/.test(value)
            return containsUppercase && containsSpecial && containsNumber
          },
          min: helpers.withMessage(
            ({
              $params
            }) => `Password must have a min length of ${$params.min} characters`,
            minLength(8)
          ),
          max: helpers.withMessage(
            ({
              $params
            }) => `Password must have a max length of ${$params.max} characters`,
            maxLength(32)
          ),
        },
        confirmPassword: {
          required: helpers.withMessage(
            () => 'Password confirmation is required',
            required
          ),
          samePassword: {
            $validator: samePass,
            $message: `Password doesn't match`
          }
        }
      }
    }
  },
};

export function samePass (pass) {
  if (pass === '') {
    return false;
  }
  return pass === this.newPassword.password;
}

</script>
<style></style>
