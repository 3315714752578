<template>
  <div class="col-xl-12 mb-5 mb-xl-0">
    <card header-classes="bg-transparent">
      <template v-slot:header>
        <div class="row align-items-center">
          <div class="col">
            <h5 class="h3 mb-0">{{language.products_and_recommendations}}</h5>
          </div>
        </div>
      </template>
      <div class="col-md-6">
          <vue-select
            :options="fingerprints"
            v-model="selectedUser"
            :placeholder="language.user_fingerprint"
            close-on-select
            searchable
            clear-on-select>
          </vue-select>
      </div>
      <base-table v-if="selectedUser" thead-classes="thead-light" :data="recommendations" :message="language.no_recommendations">
          <template v-slot:columns>
            <th>{{language.viewed_product}}</th>
            <th>{{language.recommended_products}}</th>
            <th>{{language.accessed_recommendation}}</th>
            <th>{{language.date}}</th>
          </template>

          <template v-slot:default="row">
            <td scope="row" :class="['wbm-viewed-products', {'wbm-last-line': row.item.last == true}]">
              <a :href="row.item.accessed_product.url" class="wbm-product-link" target=”_blank”>{{ row.item.accessed_product.name }}</a>
            </td>
            <td scope="row" :class="['wbm-viewed-products', {'wbm-last-line': row.item.last == true}]">
              <div v-for="(product, i) in row.item.recommendations" :key="i">
                <a :href="product.url" class="wbm-product-link" target=”_blank”><span class="h4"> {{ i+1 }}: </span>{{ product.name }}</a>
              </div>
            </td>
            <td scope="row" :class="['wbm-viewed-products', {'wbm-last-line': row.item.last == true}]">
              <a :href="row.item.accessed_recommendation.url" class="wbm-product-link" target=”_blank”>{{ row.item.accessed_recommendation.name }}</a>
            </td>
            <td scope="row" :class="['wbm-viewed-products', {'wbm-last-line': row.item.last == true}]">
              {{ row.item.date }}
            </td>
          </template>
      </base-table>

      <base-table v-if="selectedUser && Object.keys(discounts).length > 0" thead-classes="thead-light" :data="[1]" :message="language.no_discounts">
        <template v-slot:columns>
          <th>{{language.recommended_discounts}}</th>
        </template>

        <template v-slot:default="row">
          <td class="wbm-viewed-products">
          <div v-for="(discount, i) in Object.keys(discounts)" :key="i">
              <p class="wbm-product-link">{{ discount }} <span class="h4"> x {{ discounts[discount] }} views</span></p>
          </div>
          </td>
        </template>
      </base-table>

      <base-table v-if="selectedUser && users[selectedUser].checkouts != 0" thead-classes="thead-light" :data="[1]" :message="language.no_checkouts">
        <template v-slot:columns>
          <th>{{language.number_of_checkouts}}</th>
        </template>

        <template v-slot:default="row">
          <td class="wbm-viewed-products">
          <p class="wbm-product-link">{{ users[selectedUser].checkouts }}</p>
          </td>
        </template>
      </base-table>
    </card>
  </div>
</template>

<script>
export default {
    name: "recommendations-table",
    props: [
        'language',
        'fingerprints',
        'users',
        'deliveredPopups'
    ],
    data () {
        return {
            selectedUser: null,
            recommendations: {},
            discounts: {}
        }
    },
    methods: {
      getRecommendations(fingerprint) {
        let products = []
        let discounts = []
        this.deliveredPopups.forEach((element) => {
          if (element.client_fingerprint == fingerprint) {
            if (element.discount_code != null && element.discount_code != "null" && element.discount_code_viewed == 1) {
              discounts.push(element.discount_code);
            }

            if (element.recommended_products != null && element.recommended_products != "null") {
              // create a new empty object
              let product = {
                accessed_product: "",
                recommendations: [],
                accessed_recommendation: "",
                date: "",
                last: false
              };

              // get date of recommendation
              let date = new Date(element.created_at);
              product.date = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes()

              // parse the recommended products
              let json = element.recommended_products;
              let obj = JSON.parse(json);

              // add the first recommended product
              if (obj[0]) {
                product.recommendations.push({
                  url: obj[0].product_url,
                  name: obj[0].name
                });
              }
              // add the second recommended product
              if (obj[1]) {
                product.recommendations.push({
                  url: obj[1].product_url,
                  name: obj[1].name
                });
              }

              // parse the accessed product
              json = element.accessed_product;
              obj = JSON.parse(json);

              // add the accessed product
              if (obj) {
                product.accessed_product = obj;
              }

              if (element.products_clicked != "") {
                // parse the clicked recommendation
                json = element.products_clicked;
                obj = JSON.parse(json)

                // add the clicked recommendation
                if (obj) {
                  product.accessed_recommendation = obj;
                }
              }

              // push the created object with the accessed product and the recommended products in products array
              products.push(product);
            }
          }
        })
        const counts = {};
        for (const name of discounts) {
          counts[name] = counts[name] ? counts[name] + 1 : 1;
        }
        this.discounts = counts

        this.recommendations = products

        // sort data by date
        this.recommendations.sort(function(a,b) {
            let c = new Date(a.date)
            let d = new Date(b.date)
            return c-d;
        })

        this.setEndOfDay()
      },
      setEndOfDay() {
        for (let i = 0; i < this.recommendations.length - 1; i++) {
          let date = new Date(this.recommendations[i].date).getDay();
          let next = new Date(this.recommendations[i+1].date).getDay();
          if (date != next) {
            this.recommendations[i].last = true
            if (i == this.recommendations.length - 2) {
              this.recommendations[i+1].last = true
            }
          }
        }
      }
    },
    watch: {
        selectedUser: function (value) {
            this.getRecommendations(value);
        },
    },
}
</script>