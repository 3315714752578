<!-- eslint-disable -->
<template>
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div>
            <div class="text-center mb-4">
                <h1>Privacy Policy</h1>
                <br>
                <p>PROCESSING OF PERSONAL DATA</p>
                <br>
                <p>IDENTIFICATION DATA</p>
                <p class="font-weight-bold">S.C. WEBMAGNAT S.R.L</p>
                <br>
                <p class="font-weight-bold">Registered office: Splaiul Bahlui Mal Stang 37, Iasi County</p>
                <p class="font-weight-bold">Vat number: ROONRC.J22/3595/2007</p>
                <p class="font-weight-bold">Phone number: (252) 362-309, Mobile: +40 770 143 534</p>
                <p class="font-weight-bold">Email: contact@webmagnat.ro</p>
                <br>
            </div>

            <h3>We take care of your data</h3>
            <p>Protecting the information you provide is extremely important to us. Here are some things you need to know about the information we collect, the purpose for which we collect them, how we use them and who we share with them</p>
            <p>We respect the fundamental rights and freedoms of individuals, in particular, the right to the protection of personal data, by the General Rules on the processing of personal data.</p>
            <p>We are committed to complying with the requirements of European Regulation 2016/679 / EU and the rights of individual derivatives, namely to grant consent to the processing of personal data, to withdraw that consent, to request access to personal data, informed, rectify personal data, restrict their processing, erase these personal data (the right to be forgotten), port their personal data, oppose the processing.</p>
            <p>We have implemented organizational and technical security procedures to ensure the confidentiality, transparency, integrity, and availability of personal data and to comply with the requirements of European Regulation 2016/679 / EU through:</p>
            <ul>
                <li>Managing all information in paper or electronic format with responsibility and professionalism</li>
                <li>Secure efficient communication channels</li>
                <li>Implementing organizational and technical measures for limiting access to personal data, reducing the risk of disclosure of personal data and protecting personal data in electronic form and paper</li>
                <li>Processing personal data only based on an agreement on your part, through a transparent process, or by applicable law</li>
                <li>Respect your decision on collecting, using, restricting, deleting and sharing information about you</li>
            </ul>
            <br>

            <h3>Why do we collect your data?</h3>
            <p>Using your data allows us to improve our services, help us process your requests faster, and better manage your relationship with you.</p>
            <p>Here are some ways to use the information you provide:</p>
            <ul>
                <li>To give you the information you requested</li>
                <li>To send you relevant information about our services</li>
                <li>To measure the performance of our advertising campaigns</li>
                <li>To measure the performance of our services</li>
                <li>To present you a job</li>
            </ul>
            <p>The data you provide helps us to ensure that if we send you a communication, it is relevant to you.</p>
            <br>

            <h3>What kind of personal data do we collect?</h3>
            <p>To respond to requests for company services, we require personal data such as name, surname, contact details (telephone/ fax, e-mail address).</p>
            <p>To participate in the recruitment processes for any position corresponding to your qualification and experience, we request personal data such as name, surname, date of birth, age photo, sex, home address/residence, e-mail address, data on education, possession of a driving license, data on career interests.</p>
            <p>Any information we have about you is most likely provided by you - for example, you have filled out a bid form, sent a resume. You may have access at any time to the data collected about you, such as Personal Information (Name, Address, Email, Phone), Type of Request, Origin of Request, Requested Services.</p>
            <br>

            <h3>How long will we store your data</h3>
            <p>The table below shows the maximum times we will store your data. After this period, your data will be deleted safely.</p>
            <h4>Managing the (pre) contractual relationship with customers 5 years</h4>
            <h4>Send relevant information about services, performance measurement</h4>
            <h4>Promotion campaigns 5 years</h4>
            <h4>Send custom service announcement ads 3 years</h4>
            <h4>Making customer satisfaction surveys 3 years from the end of the survey</h4>
            <h4>Processing the information contained in the CV 2 years from the date of the CV's communication</h4>
            <br>

            <h3>Who can we give them access to your data?</h3>
            <p>We may give access to some of your personal information to some of our partners to improve our services. We ensure the contract that they are as committed as we do in protecting personal data and information.</p>
            <p>Service Providers</p>
            <p>We provide your data to our service companies:</p>
            <ul>
                <li>data analysis firms</li>
                <li>legal consulting firms</li>
                <li>consultancy consultancy</li>
            </ul>
            <p>All these companies have strict obligations to protect your data.</p>
            <p>Reasons for compliance and security</p>
            <p>We may need to disclose your data to public and governmental authorities for reasons of national security, law enforcement, or other public interest reasons. You will be informed under the law before such a transfer.</p>
            <p>We may also disclose personal information if we determine in good faith that disclosure is necessary to protect our rights, resolve legal conflicts, comply with terms and conditions, investigate fraud or protect our users.</p>
            <p>We do not transfer your data to the European Economic Area</p>
            <br>

            <h3>What are your rights?</h3>
            <p>You can access your data or ask to be deleted or modified at any time. You can also request to receive your data in a standard format.</p>
            <p>You may object that your data will be processed or request that you restrict the use of your data.</p>
            <p>Any communications about personal data will be made at contact@webmagnat.ro and for paper communications at the company's registered office.</p>
            <p>Finally, if you feel necessary, you have the right to file a complaint with the competent data protection authority.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

