<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'app',
    created () {
      document.title = "SmartBUSINESS";
      const link = document.querySelector("[rel='icon']");
      if (link) {
        link.setAttribute('href', 'https://ik.imagekit.io/etrqawouhvc/Smart/logo-smart_CsZpXfVqO.png?updatedAt=1639991861957');
      }
    }
  }
</script>
