<template>
  <div class="main-content bg-default row auth-content mr-0 ml-0">
    <div class="col-lg-7 auth_container">
      <div>
        <img class="auth_logo" src="https://cdn.filestackcontent.com/CW4Sz8TOQmazXSD1PLFn">
      </div>
      <div class="auth_title">
        <h1 class="auth_title--text">Shorten 10x the time it takes for your customers to </h1>
        <h1 class="auth_title--text">find and buy a product in your online store </h1>
      </div>
    </div>
    <div class="col-md-12 col-lg-5 col-sm-12 col-xs-12 pr-0 pl-0">
      <!-- Page content -->
      <div class="px-0 vh-100">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "auth-additional-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
      language: ''
    };
  },
  created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  }
};
</script>
<style>
.footer-container {
  height: 22vh;
  text-align: center;
}
.translation-container{
  margin-top: 8px;
}
</style>
