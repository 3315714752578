<template>
  <div class="container translation-root text-right mr-5">
    <div class="translation-container">
      <base-dropdown>
          <template v-slot:title>
            <base-button type="secondary" class="dropdown-toggle language-toggle" >
              <img src="https://demos.creative-tim.com/argon-design-system/assets/img/icons/flags/GB.png" v-if="language === 'en' " />
              <img src="https://demos.creative-tim.com/argon-design-system/assets/img/icons/flags/RO.png" v-if="language === 'ro' " />
            </base-button>
          </template>
          <li v-if="language === 'en' " @click="changeLanguage('ro')">
            <a class="dropdown-item" href="#">
              <img src="https://demos.creative-tim.com/argon-design-system/assets/img/icons/flags/RO.png" /> {{languageVar.romanian}}
            </a>
          </li>
          <li v-if="language === 'ro' " @click="changeLanguage('en')">
            <a class="dropdown-item" href="#">
              <img src="https://demos.creative-tim.com/argon-design-system/assets/img/icons/flags/GB.png" /> {{languageVar.english}}
            </a>
          </li>
        </base-dropdown>
    </div>
  </div>
</template>

<script>
import BaseDropdown from "./BaseDropdown";
export default {
  name: "Translation",
  components: {
    BaseDropdown
  },
  data() {
    return {
      language: 'en',
      languageVar: ''
    };
  },
  methods: {
    changeLanguage(lang) {
      localStorage.setItem('language', lang);
      window.location.reload();
    }
  },
  created() {
    this.language = localStorage.getItem('language');
    setTimeout(() => {
      this.languageVar = this.$store.state.languageVariable;
    },100);
  }
}
</script>

<style>
.translation-container .language-toggle {
  padding: 8px !important;
}
.translation-container .dropdown-menu {
  min-width: initial !important;
}
</style>