<template>
  <div class="justify-content-center align-content-center bg-white vh-100">
    <div>
      <div class="auth_login--container">
        <div class="text-left mb-4 auth_login--title">
          <h1>{{ language.sign_in_message }}</h1>
        </div>
        <!-- Login credentials form -->
        <form @submit.prevent class="auth_login--form">
          <div class="form-group input-group input-group-alternative">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="ni ni-email-83"></i>
              </span>
            </div>
            <input
              placeholder="Email"
              class="form-control input-group-alternative"
              v-model="credentials.email"
              focused
              />
          </div>
          <!-- Validation error -->
          <div class="input-errors" v-for="(error, index) of v$.credentials.email.$errors" :key="index">
            <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
          </div>
          <div class="form-group input-group input-group-alternative">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="ni ni-lock-circle-open"></i>
              </span>
            </div>
            <input
              :placeholder="language.password"
              type="password"
              class="form-control input-group-alternative"
              v-model="credentials.password"
              v-on:keyup.enter="login"
              />
          </div>
          <!-- Validation error -->
          <div class="input-errors" v-for="(error, index) of v$.credentials.password.$errors" :key="index">
            <div class="text-red mb-2 mt--3">{{ error.$message }}</div>
          </div>
          <!-- Server errors -->
          <div v-if="error" class="row mt-4">
            <div class="col-12">
              <span class="text-danger" v-html="error"/>
            </div>
          </div>
            <div v-if="verifiedError" class="row mt-4">
            <div class="col-12">
              <span class="text-danger">Please verify your account!</span>
            </div>
          </div>
          <!-- Submit form button -->
          <div class="text-center">
            <base-button type="primary" class="my-4 auth_login--submit" @click="login">{{language.sign_in}}</base-button>
          </div>
        </form>
        <div class="row mt-3 ml-0 mr-0 auth_login--links">
          <div class="col-6 pl-0">
            <router-link :to="{ name: 'Forgot Password' }" class="text-light"><small>{{language.forgot_password}}?</small></router-link>
          </div>
          <div class="col-6 text-right pr-0">
            <router-link to="/register" class="text-light">
              <small>{{language.create_new_account}}</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  name: "login",
  data() {
    return {
      credentials: {
        email: "",
        password: "",
      },
      error: '',
      valid: false,
      language: '',
      verifiedError: false
    };
  },
  setup () {
    return { v$: useVuelidate() }
  },
  methods: {
    async login() {
      try {
        this.verifiedError = false;
        this.error = '';

        // reset form after submission
        this.v$.credentials.$touch();
        if (this.v$.credentials.$pending || this.v$.credentials.$error) return;

        // send login credentials
        const response = await AuthService.login({
          email: this.credentials.email,
          password: this.credentials.password
        })

        // set the store token and logged in user
        this.$store.dispatch('setUserToken', response.data.token)
        this.$store.dispatch('setUser', response.data.user)

        // check if products are loaded and popup is configured
        this.$store.dispatch("fetchUserConditions", { self: this })
        
        if (response.data.user.roles === "ADMIN") {
          this.$router.push({
            name: 'dashboard-admin'
          })
        } else {
          // if the user has his profile data completed set the local storage
          
          // if the user is verified redirect to his profile else redirect to validation page
          if (response.data.user.is_verified == 1) {
            this.$store.dispatch('setVerification', true);
    
            if (response.data.user.is_complete == 1) {
              this.$store.dispatch('setDataCompleted', true);
              this.$router.push({
                name: 'reports'
              })
            } else {
              this.$store.dispatch('setDataCompleted', false);
              this.$router.push({
                name: 'installation'
              })
            }
            
          } else {
            this.verifiedError = true;
          }
        }
        sessionStorage.setItem("isAdmin", JSON.stringify(response.data.isAdmin));
      } catch (error) {
        if (error.response.status == 404) {
          this.error = error.response.data.message
        } else if (error.response.status == 422) {
          this.error = error.response.data.message
        }
      }
    }
  },
  created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  },
  // validations for login form
  validations() {
    return {
      credentials: {
        email: { 
          required: helpers.withMessage(
            () => 'Email is required',
            required
          )
         },
        password: { 
          required: helpers.withMessage(
            () => 'Password is required',
            required
          )
        }
      }
    }
  },
};
</script>
<style></style>
