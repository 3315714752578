import Api from '@/services/Api'

export default {
    products_custom() {
        return Api.get('products/custom_feed');
    },

    products_compari() {
        return Api.get('products/compari_feed');
    },

    products_google() {
        return Api.get('products/google_feed');
    },

    products_ebay() {
        return Api.get('products/ebay_feed');
    },

    custom_feed(data) {
        return Api.post('file-import', data);
    },

    compari_feed(data) {
        return Api.post('products-feed/process-compari-feed', data);
    },

    ebay_feed(data) {
        return Api.post('products-feed/process-ebay-feed', data);
    },

    google_feed(data) {
        return Api.post('products-feed/process-google-feed', data);
    },

    sort_change(data) {
        return Api.post('products/sort', data);
    }
}