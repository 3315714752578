<!-- eslint-disable -->
<template>
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center mb-4">
            <h1>Terms and conditions</h1>
            <br>
            <p>PROCESSING OF PERSONAL DATA</p>
            <br>
            <p>IDENTIFICATION DATA</p>
            <p class="font-weight-bold">S.C. WEBMAGNAT S.R.L</p>
            <br>
            <p class="font-weight-bold">Registered office: Splaiul Bahlui Mal Stang 37, Iasi County</p>
            <p class="font-weight-bold">Vat number: ROONRC.J22/3595/2007</p>
            <p class="font-weight-bold">Phone number: (252) 362-309, Mobile: +40 770 143 534</p>
            <p class="font-weight-bold">Email: contact@webmagnat.ro</p>
            <br>
            <p>Thank you for visiting us!</p>
            <p>WebMagnat recommends to carefully read the terms and conditions for the proper use of our site. By visiting and/or ordering of the products posted on this site you are agreeing to the terms and conditions below.</p>
            <p>TERMS AND CONDITIONS:</p>
            <p>By using <a href="https://smartbusiness.digital">smartbusiness.digital</a> site our clients are agreeing to all terms and conditions, as well as to the supplier’s right to perform changes on the site content without any prior notice.</p>
            <p>The content of the <a href="smartbusiness.digital">smartbusiness.digital</a> site is the exclusive property of Company S.C. WEBMAGNAT S.R.L which owns all the intellectual property rights.</p>
            <p>NOTICE ON PERSONAL DATA PROTECTION</p>
            <p>WEBMAGNAT S.R.L registered office in Splaiul Bahlui Mal Stang 37, Iasi County, processes, under safety conditions and solely for the purposes specified below, the personal data you provide by compliance with the requirements of Regulation (EU) 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation) and of Law no. 506/2004 on the protection of personal data and safeguard the right to privacy in the electronic communications sector.</p>
            <p>The type of personal data which we process cover, for instance, name, mail address, date of birth, genre, phone/fax number, email address or other information collected on registration. By providing such data you are agreeing to their processing, in compliance with this confidentiality statement.</p>
            <p>The information about you may be used by us solely for purposes associated with the marketing of services and products in our online store as follows:</p>
            <p>Registration of information about you for the purpose of promoting special offers, discounts and of other benefits.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
