<template>
  <div class="justify-content-center vh-100 bg-white">
    <div>
      <div class="auth_register--container">
        <div v-if="!registered" class="py-lg-5">
          <div class="text-left mb-4 auth_register--title">
            <h1>{{language.create_your_account}}<span class="auth_register--subtitle"></span></h1>
            <span class="auth_register--subtitle">15 minutes Fast Onboarding</span>
          </div>
          <!-- Register credentials form -->
          <form @submit.prevent id="registerForm">
            <div class="form-group input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-email-83"></i>
                </span>
              </div>
              <input
                placeholder="Email"
                class="form-control input-group-alternative"
                v-model="credentials.email"
                focused
                name="email"
                />
            </div>
            <!-- Validation error -->
            <div class="input-errors" v-if="v$.credentials.email.$errors.length > 0">
              <div class="text-red mb-2 mt--3">{{ v$.credentials.email.$errors[0].$message }}</div>
            </div>
            <div class="form-group input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-lock-circle-open"></i>
                </span>
              </div>
              <input
                :placeholder="language.password"
                type="password"
                name="password"
                class="form-control input-group-alternative"
                v-model="credentials.password"
                />
            </div>
            <!-- Validation error -->
            <div class="input-errors" v-if="isSubmited && credentials.password && v$.credentials.password.$invalid">
                <div class="text-red mb-4 mt--3">Password should contain at least one uppercase, one number and one special chacter</div>
            </div>
            <div class="input-errors" v-if="v$.credentials.password.$errors.length > 0">
              <div class="text-red mb-2 mt--3">{{ v$.credentials.password.$errors[0].$message }}</div>
            </div>
            <div class="form-group input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-lock-circle-open"></i>
                </span>
              </div>
              <input
                :placeholder="language.confirm_password"
                type="password"
                name="password_confirmation"
                class="form-control input-group-alternative"
                v-model="credentials.confirmPassword"
                />
            </div>
            <div class="form-group input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-tag"></i>
                </span>
              </div>
              <input
                :placeholder="language.discount_code"
                type="text"
                name="discount_code"
                class="form-control input-group-alternative"
                v-model="credentials.discount_code"
                />
            </div>
            <!-- Validation error -->
            <div class="input-errors" v-if="v$.credentials.confirmPassword.$errors.length > 0">
              <div class="text-red mb-2 mt--3">{{ v$.credentials.confirmPassword.$errors[0].$message }}</div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="custom-control custom-checkbox custom-control-alternative">
                  <input
                    :id="'terms-' + cbId"
                    class="custom-control-input"
                    type="checkbox"
                    v-model="terms"/>
                  <label class="custom-control-label" :for="'terms-' + cbId">
                    <span class="text-muted">
                      {{language.agree}} <a href="terms-and-conditions" target="_blank">{{language.terms_and_conditions}}</a> {{language.and}} <a href="privacy-policy" target="_blank">{{language.privacy_policy}}</a>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <!-- Recaptcha validation -->
            <div class="g-recaptcha d-flex mt-3"
              name="g-recaptcha-response"
              data-sitekey="6LcJ5aQdAAAAAEt7AjU40mtz1dxXXuIYSO_k0gfm"
              data-callback="verifyRecaptchaCallback"
              data-expired-callback="expiredRecaptchaCallback"></div>
            <!-- Server errors -->
            <div v-if="registerError" class="row mt-4">
              <div class="col-12">
                <span class="text-danger" v-html="registerError"/>
              </div>
            </div>
            <!-- Submit form button -->
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4 auth_register--submit"
                @click="register"
                >{{language.create_account}}</base-button>
            </div>
          </form>
          <div class="row mt-3 ml-0 mr-0 auth_login--links">
            <div class="col-6 pr-0 text-left">
              <router-link to="/login" class="text-light">
                <small>{{language.login_into_account}}</small>
              </router-link>
            </div>
            <div class="col-6 pl-0 text-right">
              <router-link :to="{ name: 'Forgot Password' }" class="text-light"><small>{{language.forgot_password}}?</small></router-link>
            </div>
          </div>
        </div>
        <!-- Success message -->
        <!-- <div v-if="registered" class="card-body px-lg-5 py-lg-5">
          <div class="text-center mb-2">
            <h3>Please check your email!</h3>
            <p>Thank you for joining us! Please check your email to set up your account!</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService'
import { randomString } from "../components/stringUtils";
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email, helpers } from '@vuelidate/validators'

export default {
  name: "register",
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      credentials: {
        email: '',
        password: '',
        confirmPassword: '',
        discount_code: ''
      },
      terms: false,
      recaptcha: false,
      registerError: null,
      cbId: '',
      language: '',
      registered: false,
      isSubmited: false
    };
  },
  async mounted () {
    this.cbId = randomString()
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?h1=en')
    document.head.appendChild(recaptchaScript)
  },
  methods: {
    async register () {
      try {
        this.isSubmited = true
        this.registerError = null
        // reset form after submission
        this.v$.credentials.$touch();
        if (this.v$.credentials.$pending || this.v$.credentials.$error || this.v$.credentials.$invalid) return;

        // check if terms are checked
        if (!this.terms) {
          this.registerError = "Please agree Terms and Conditions."
          return
        }

        // check if recaptcha is checked
        if (!(this.isCaptchaChecked())) {
          this.registerError = "Please check the recaptcha verification."
          return
        }

        let myForm = document.getElementById("registerForm");
        let formData = new FormData(myForm);
        // send register credentials
        const response = await AuthService.register(formData)

        this.$store.dispatch('setUserToken', response.data.token)
        this.$store.dispatch('setUser', response.data.user)
        this.$store.dispatch('setVerification', true);
        this.$store.dispatch('setDataCompleted', false);

        // check if products are loaded and popup is configured
        this.$store.dispatch("fetchUserConditions", { self: this })
        
        this.$router.push({
          name: 'installation'
        })

        // this.registered = true;
      } catch (err) {
        if (err.response.status == 400 || err.response.status == 422) {
          this.registerError = err.data.message
        }
      }
    },
    isCaptchaChecked() {
      return grecaptcha && grecaptcha.getResponse().length !== 0;
    }
  },
  created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  },
  validations() {
  // validations for register form
    return {
      credentials: {
        email: {
          required: helpers.withMessage(
            () => 'Email is required',
            required
          ),
          email: helpers.withMessage(
            () => 'You have entered an invalid email address.',
            email
          )
        },
        password:
        {
          required: helpers.withMessage(
            () => 'Password is required',
            required
          ),
          valid: function(value) {
            const containsUppercase = /[A-Z]/.test(value)
            const containsSpecial = /[#?!@$%^&*-]/.test(value)
            const containsNumber = /[0-9]/.test(value)
            return containsUppercase && containsSpecial && containsNumber
          },
          min: helpers.withMessage(
            ({
              $params
            }) => `Password must have a min length of ${$params.min} characters`,
            minLength(8)
          ),
          max: helpers.withMessage(
            ({
              $params
            }) => `Password must have a max length of ${$params.max} characters`,
            maxLength(32)
          ),
        },
        confirmPassword: {
          required: helpers.withMessage(
            () => 'Password confirmation is required',
            required
          ),
          samePassword: {
            $validator: samePass,
            $message: `Password doesn't match`
          }
        }
      }
    }
  },
};

export function samePass (pass) {
  if (pass === '') {
    return false;
  }
  return pass === this.credentials.password;
}

</script>
