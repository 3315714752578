<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Dashboard"
      title="Dashboard"
    >
      <template v-slot:links>
        <!-- <sidebar-item
          :link="{
            name: language.user_profile,
            icon: 'ni ni-circle-08 text-yellow',
            path: '/profile',
          }"
        /> -->

        <!-- add v-if="$store.state.isCompleted" to elements  -->
        
        <!-- add v-if="$store.state.isCompleted && $store.state.popupConfig && $store.state.productsLoaded" -->
        <sidebar-item
          :link="{
            name: language.fast_onboarding,
            icon: 'ni ni-laptop text-orange',
            path: '/fast-onboarding',
          }"
        />

        <!-- <div class="nav-item nav-link" v-else>
          <i class="ni ni-laptop text-grey"></i>
          <span class="nav-link-text my-2">{{language.installer}}</span>
        </div> -->

        <sidebar-item
          v-if="$store.state.isCompleted"
          :link="{
            name: language.products,
            icon: 'ni ni-bullet-list-67 text-red',
            path: '/products',
          }"
        />

        <div class="nav-item nav-link" v-else>
          <i class="ni ni-bullet-list-67 text-grey"></i>
          <span class="nav-link-text my-2">{{ language.products }}</span>
        </div>

        <sidebar-item
          v-if="$store.state.isCompleted"
          :link="{
            name: language.orders,
            icon: 'ni ni-single-copy-04 text-pink',
            path: '/orders',
          }"
        />

        <div class="nav-item nav-link" v-else>
          <i class="ni ni-single-copy-04 text-grey"></i>
          <span class="nav-link-text my-2">{{ language.orders }}</span>
        </div>

        <sidebar-item
          v-if="$store.state.isCompleted"
          :link="{
            name: language.settings,
            icon: 'ni ni-settings-gear-65 text-blue',
            path: '/settings',
          }"
        />

        <div class="nav-item nav-link" v-else>
          <i class="ni ni-settings-gear-65 text-grey"></i>
          <span class="nav-link-text my-2">{{ language.settings }}</span>
        </div>

        <sidebar-item
          v-if="$store.state.isCompleted"
          :link="{
            name: language.reports,
            icon: 'ni ni-chart-pie-35 text-green',
            path: '/reports',
          }"
        />

        <div class="nav-item nav-link" v-else>
          <i class="ni ni-chart-pie-35 text-grey"></i>
          <span class="nav-link-text my-2">{{ language.reports }}</span>
        </div>

        <!-- <sidebar-item
          v-if="$store.state.isCompleted"
          :link="{
            name: language.pay,
            icon: 'ni ni-shop text-purple',
            path: '/pay',
          }"
        />
        <div class="nav-item nav-link" v-else>
          <i class="ni ni-shop text-grey"></i>
          <span class="nav-link-text my-2">Pay</span>
        </div> -->

        <sidebar-item
          v-if="$store.state.isCompleted"
          :link="{
            name: language.users,
            icon: 'ni ni-single-02 text-pink',
            path: '/users',
          }"
        />

        <div class="nav-item nav-link" v-else>
          <i class="ni ni-single-02 text-grey"></i>
          <span class="nav-link-text my-2">{{ language.users }}</span>
        </div>

        <sidebar-item
            v-if="$store.state.isCompleted"
            :link="{
            name: language.stores,
            icon: 'ni ni-shop text-blue',
            path: '/stores',
          }"
        />

        <div class="nav-item nav-link" v-else>
          <i class="ni ni-shop text-grey"></i>
          <span class="nav-link-text my-2">{{ language.stores }}</span>
        </div>

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import ContentFooter from "./ContentFooter.vue";
import SidebarItem from '../components/SidebarPlugin/SidebarItem.vue';

export default {
  components: {
    ContentFooter,
    SidebarItem,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      currentUser: {},
      language: ''
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  }
};
</script>
<style lang="scss"></style>
