<!-- eslint-disable -->
<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 300px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{language.configuration}}</h1>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Pop-up</h3>
                  </div>
                </div>
              </div>
            </template>

            <!-- Popup configuration form -->
            <form @submit.prevent id="configureForm">
              <h6 class="heading-small text-muted mb-4">{{language.choose_color}}:</h6>
              <!-- Colorpicker -->
              <div class="pl-lg-4">
                  <div class="row">
                      <ColorPicker
                          style="width: 220px;"
                          theme="light"
                          :color="formData.color"
                          @changeColor="changeColor"
                          name="color"
                          />
                          <div class="wbm-color-picker" :style="{background: formData.color}"/>
                  </div>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.formData.color.$errors" :key="index">
                <div class="text-red mb-2">{{ error.$message }}</div>
              </div>
              <!-- <h6 class="heading-small text-muted mb-4 mt-5">{{language.upload_icon}}:</h6> -->
              <!-- Upload icon -->
              <!-- <div class="pl-lg-4">
                  <div class="row">
                      <div class="col-lg-12">
                          <input ref="popupIcon" type="file" name="icon" accept="image/*" @change="selectFile('icon')" hidden>
                          <base-button type="primary" @click.prevent="chooseFiles('icon')">{{language.upload}}</base-button>
                          <span v-if="formData.icon">{{ formData.icon.name }}</span>
                      </div>
                  </div>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.formData.icon.$errors" :key="index">
                <div class="text-red mb-2">{{ error.$message }}</div>
              </div> -->
              <!-- Choose popup form -->
              <h6 class="heading-small text-muted mb-4 mt-5">{{language.layout}}:</h6>
              <div class="pl-lg-4">
                  <div class="row">
                      <div class="col-lg-4 popup-form">
                          <input type="radio" id="form1" name="popup_form" value="form1" v-model="formData.popup_form">
                            <img class="popup-form-img" for="form1" src="https://ik.imagekit.io/etrqawouhvc/form2_1__78WKzgTDc.png?updatedAt=1632132407208">
                      </div>
                      <div class="col-lg-4 popup-form">
                          <input type="radio" id="form2" name="popup_form" value="form2" v-model="formData.popup_form">
                            <img class="popup-form-img" for="form2" src="https://ik.imagekit.io/etrqawouhvc/form3_pUIJ3OTiy.png?updatedAt=1632130602909">
                      </div>
                      <div class="col-lg-4 popup-form">
                          <input type="radio" id="form3" name="popup_form" value="form3" v-model="formData.popup_form">
                          &nbsp;
                          <img class="popup-form-img-small" src="https://ik.imagekit.io/etrqawouhvc/form1_cMRyFEORw.png?updatedAt=1632132402864">
                      </div>
                  </div>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.formData.popup_form.$errors" :key="index">
                <div class="text-red mb-2">{{ error.$message }}</div>
              </div>
              <h6 class="heading-small text-muted mb-4 mt-5">{{language.position}}:</h6>
              <!-- Choose popup position -->
              <div class="pl-lg-4">
                  <div class="row">
                      <div class="col-lg-4">
                          <input type="radio" id="left" name="position" value="left" v-model="formData.position">
                            <label for="form1">{{language.left}}</label>
                      </div>
                      <div class="col-lg-4">
                          <input type="radio" id="right" name="position" value="right" v-model="formData.position">
                            <label for="form2">{{language.right}}</label>
                      </div>
                  </div>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.formData.position.$errors" :key="index">
                <div class="text-red mb-2">{{ error.$message }}</div>
              </div>
              <h6 class="heading-small text-muted mb-4 mt-5">{{language.popup_content}}:</h6>
              <!-- Upload messages and discounts -->
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-2 col-md-3 custom-control custom-checkbox custom-control-alternative inline">
                      <input
                        :id="'messages-' + cbId"
                        class="custom-control-input"
                        type="checkbox"
                        v-model="formData.isMessage"/>
                      <label class="custom-control-label" :for="'messages-' + cbId">{{language.add_marketing_messages}}</label>
                  </div>
                  <input ref="messages" type="file" name="csvContentMessages" accept=".xlsx, .xls, .csv" @change="selectFile('messages')" hidden>
                  <base-button v-if="formData.isMessage == true" class="col-lg-1 upload-btn" type="primary" @click.prevent="chooseFiles('messages')">{{language.upload}}</base-button>
                  <span v-if="formData.isMessage == true && formData.csvContentMessages.name" class="mr-4">{{ formData.csvContentMessages.name }}</span>
                  <base-button
                    v-if="biases.length > 0 || introductionMessages.length > 0"
                    type="primary"
                    class="col-lg-1 see-data-btn"
                    @click="messagesTab = true">{{language.see_data}}</base-button>
                </div>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.formData.csvContentMessages.$errors" :key="index">
                <div class="text-red mb-2">{{ error.$message }}</div>
              </div>
              <!-- Set the popup frequency per day -->
              <h6 class="heading-small text-muted mb-4 mt-5">{{language.popup_display_frequency}}:</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-2 col-md-3">
                      <div class="form-group input-group input-group-alternative mt-2">
                        <input
                          placeholder="Popup frequency (Ex: 100)"
                          class="form-control input-group-alternative"
                          v-model="formData.popupFrequency"
                          focused
                          name="popupFrequency"
                          />
                      </div>
                  </div>
                </div>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.formData.popupFrequency.$errors" :key="index">
                <div class="text-red mb-2">{{ error.$message }}</div>
              </div>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12 col-lg-12 col-sm-12 text-right">
                      <p v-if="error" class="text-red">{{ error }}</p>
                      <button type="submit" class="btn btn-primary my-1" @click="saveData">{{language.save}}</button>
                  </div>
                </div>
              </div>
            </form>
          </card>
          <!-- Modal to show introduction messages and biases -->
          <modal v-model:show="messagesTab"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-lg">
            <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
                <div class="text-muted mb-4">
                  <span>{{language.intro_messages}}</span>
                </div>
                <div class="table-responsive">
                  <base-table thead-classes="thead-light" :data="introductionMessages">
                    <template v-slot:columns>
                      <th>NO</th>
                      <th>{{language.message}}</th>
                      <th class="text-right">
                        {{language.select_all}}
                        <div class="text-right custom-control custom-checkbox custom-control-alternative inline pt-1">
                          <input
                          id="allIntro"
                          class="custom-control-input"
                          type="checkbox"
                          @click="checkAllMessages()"
                          v-model="checkAllIntroMessages"
                          />
                          <label class="custom-control-label" for="allIntro"></label>
                          </div>
                      </th>
                    </template>
                    <template v-slot:default="row">
                      <th scope="row">
                        {{ row.index + 1}}
                      </th>
                      <td>
                        {{ row.item.messages }}
                      </td>
                        <td>
                          <div class="text-right custom-control custom-checkbox custom-control-alternative inline">
                          <input
                          :id="'intro-' + row.item.id"
                          :value="row.item.id"
                          v-model="idsIntroductionMessages"
                          @change="updateCheckedMessages()"
                          class="custom-control-input"
                          type="checkbox"/>
                          <label class="custom-control-label" :for="'intro-' + row.item.id"></label>
                          </div>
                      </td>
                    </template>
                  </base-table>
                  <div v-if="idsIntroductionMessages.length > 0" class="text-right mr-3">
                    <base-button
                      type="primary"
                      @click="deleteIntroMessages()">{{language.delete}}</base-button>
                  </div>
                </div>
                <div class="text-muted mb-4">
                    <span>{{language.messages_discounts}}</span>
                </div>
                <div class="table-responsive">
                  <base-table thead-classes="thead-light" :data="biases">
                    <template v-slot:columns>
                      <th>NO</th>
                      <th>{{language.messages}}</th>
                      <th>{{language.discount_messages}}</th>
                      <th class="text-right">
                        {{language.select_all}}
                        <div class="text-right custom-control custom-checkbox custom-control-alternative inline pt-1">
                          <input
                          id="allDisc"
                          class="custom-control-input"
                          type="checkbox"
                          @click="checkAllBiases()"
                          v-model="checkedAllDiscounts"/>
                          <label class="custom-control-label" for="allDisc"></label>
                          </div>
                      </th>
                    </template>
                    <template v-slot:default="row">
                      <th scope="row">
                          {{ row.index + 1}}
                      </th>
                      <td>
                          {{ row.item.messages }}
                      </td>
                      <td>
                          {{ row.item.discount_codes.discount_codes }}
                      </td>
                      <td>
                          <div class="text-right custom-control custom-checkbox custom-control-alternative inline">
                          <input
                          :id="'disc-' + row.item.id"
                          :value="row.item.id"
                          v-model="idsBiases"
                          @change="updateCheckedBiases()"
                          class="custom-control-input"
                          type="checkbox"/>
                          <label class="custom-control-label" :for="'disc-' + row.item.id"></label>
                          </div>
                      </td>
                    </template>
                  </base-table>
                  <div v-if="idsBiases.length > 0" class="text-right mr-3">
                    <base-button
                      type="primary"
                      @click="deleteBiases()">{{language.delete}}</base-button>
                  </div>
                </div>
            </card>
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css" src="@/assets/css/vue-color-kit.css"></style>
<script>
import useVuelidate from '@vuelidate/core';
import { ColorPicker } from 'vue-color-kit';
import { randomString } from "../components/stringUtils";
import BaseButton from '../components/BaseButton.vue';
import PopupService from '../services/PopupService';
import { required, numeric } from '@vuelidate/validators';

export default {
  name: "configuration",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    ColorPicker,
  },
  data () {
    BaseButton
    return {
      popupConfig: {},
      formData: {
        color: '',
        popup_form: '',
        position: '',
        isMessage: '',
        csvContentMessages: '',
        popupFrequency: ''
      },
      error: null,
      cbId: '',
      messagesTab: false,
      biases: [],
      introductionMessages: [],
      idsIntroductionMessages: [],
      idsBiases: [],
      checkAllIntroMessages: false,
      checkedAllDiscounts: false,
      language: ''
    };
  },
  async created () {
    this.cbId = randomString()

    // Get popup data for the logged user company_id
    this.getPopup();

    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  },
  methods: {
    async getPopup () {
      // Populate form data if popup configuration aleardy exists
      this.popupConfig = (await PopupService.getPopup()).data

      if (this.popupConfig.popup !== null) {
        this.formData.color = this.popupConfig.popup.color
        this.formData.popup_form = this.popupConfig.popup.popup_form
        this.formData.position = this.popupConfig.popup.position
        this.formData.icon = this.popupConfig.popup.media[0]
        this.formData.popupFrequency = this.popupConfig.popup.daily_popup_nr
        this.biases = this.popupConfig.biases
        this.introductionMessages = this.popupConfig.introductionMessages
      }
      
      if (this.biases.length > 0 || this.introductionMessages.length > 0) {
        this.formData.isMessage = true
      }
    },
    changeColor (color) {
      // Handle color change in form data
      const { r, g, b, a } = color.rgba
      this.formData.color = `rgba(${r}, ${g}, ${b}, ${a})`
    },
    chooseFiles (type) {
      // Open file input when button is clicked
      if (type == 'icon') {
        this.$refs["popupIcon"].click()
      } else if (type == 'messages') {
        this.$refs["messages"].click()
      }
    },
    selectFile (type) {
      // save selected files in form object
      if (type == 'icon') {
        this.formData.icon = this.$refs["popupIcon"].files[0]
      } else if (type == 'messages') {
        this.formData.csvContentMessages = this.$refs["messages"].files[0]
      }
    },
    async saveData () {
    /**
     * Save popup configuration form data
     * Append collor to form data
     */
      this.error = null
      this.v$.formData.$touch()
      if (this.v$.formData.$pending || this.v$.formData.$error) return
      let form = document.getElementById("configureForm")
      let data = new FormData(form)
      data.append('color', this.formData.color)

    /**
     * Make POST request if the popup has no saved data
     * Make PUT request if the data is updated
     */
    
      if (this.popupConfig.popup === null) {
        try {
          await PopupService.addConfig(data);
          this.$store.dispatch("fetchUserConditions", { self: this })
          this.getPopup()
          this.$swal('Saved', 'Popup configuration saved successfully!', 'success')
        } catch (err) {
          console.log(err)
          this.$swal('Error', 'Sorry! An error has occured.', 'error')
        }
      } else {
        try {
          data.append( '_method', 'PUT' );
          await PopupService.updateConfig(data, this.popupConfig.popup.company_id)
          this.getPopup()
          this.$swal('Updated', 'Popup configuration updated successfully!', 'success')
        } catch (err) {
          console.log(err)
          this.$swal('Error', 'Sorry! An error has occured.', 'error')
        }
      }
      this.checkedAllDiscounts = false
      this.checkAllIntroMessages = false
    },
    // Check all messages and save them
    checkAllMessages () {
      this.checkAllIntroMessages = !this.checkAllIntroMessages

      if (this.checkAllIntroMessages) {
        this.idsIntroductionMessages = this.introductionMessages.map(item => item.id)
      } else {
        this.idsIntroductionMessages = []
      }
    },
    // Update checked messages when one is checked/unchecked
    updateCheckedMessages () {
      if (this.idsIntroductionMessages.length === this.introductionMessages.length) {
        this.checkAllIntroMessages = true
      } else {
        this.checkAllIntroMessages = false
      }
    },
    // Check all discounts and save them
    checkAllBiases () {
      this.checkedAllDiscounts = !this.checkedAllDiscounts

      if (this.checkedAllDiscounts) {
        this.idsBiases = this.biases.map(item => item.id)
      } else {
        this.idsBiases = []
      }
    },
    // Update checked discounts when one is checked/unchecked
    updateCheckedBiases () {
      if (this.idsBiases.length === this.biases.length) {
        this.checkedAllDiscounts = true
      } else {
        this.checkedAllDiscounts = false
      }
    },
    async deleteIntroMessages () {
      // Delete checked intro messages
      await PopupService.deleteIntroMessages(this.idsIntroductionMessages)
       for (var i = 0; i < this.idsIntroductionMessages.length; i++) {
        for (var j = 0; j < this.introductionMessages.length; j++) {
          if (this.introductionMessages[j].id == this.idsIntroductionMessages[i]) {
            this.introductionMessages.splice(j, 1);
          }
        }
      }
      this.idsIntroductionMessages = [];
    },
    async deleteBiases () {
      // Delete checked biases
      await PopupService.deleteBiases(this.idsBiases);
      for (var i = 0; i < this.idsBiases.length; i++) {
        for (var j = 0; j < this.biases.length; j++) {
          if (this.biases[j].id == this.idsBiases[i]) {
            this.biases.splice(j, 1)
          }
        }
      }
      this.idsBiases = []
    }
  },
  // Form validations
  validations() {
    return {
      formData: {
        color: { required },
        popup_form: { required },
        position: { required },
        csvContentMessages: {
          checkMessages: {
            $validator: checkMessages,
            $message: `Please load messages.`
          }
        },
        popupFrequency: { required, numeric }
      },
    }
  },
};

// Check if messages are required
export function checkMessages(messages) {
  if (this.formData.isMessage == false) {
    return true
  } else if (messages.name || this.introductionMessages.length > 0 || this.biases.length > 0) {
      return true
  }
  return false
}

</script>
