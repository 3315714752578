<template>
  <div>
    <base-header
        class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style="
        min-height: 300px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
    "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{language.stores}}</h1>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{language.stores_list}}</h3>
                  </div>
                  <div class="col-4 text-right">
                    <base-button type="primary" class="my-4" @click="newStoreModal = true; editMode = false;">{{language.add_store}}</base-button>
                  </div>
                </div>
              </div>
            </template>
            <div class="card-body">
              <table class="table table-bordered">
                <thead>
                  <th class="text-center">{{language.store_name}}</th>
                  <th class="text-center">{{language.location}}</th>
                  <th class="text-center">{{language.country}}</th>
                  <th class="text-center">{{language.county}}</th>
                  <th class="text-center">{{language.city}}</th>
                  <th class="text-center">{{language.qr_code}}</th>
                  <th class="text-center">{{language.product_feed}}</th>
                  <th class="text-center">{{language.actions}}</th>
                </thead>
                <tbody>
                  <template v-for="(store, index) in stores">
                    <tr>
                      <th class="text-center align-middle">{{store.store_name}}</th>
                      <th class="text-center align-middle">{{store.location}}</th>
                      <th class="text-center align-middle">{{store.country}}</th>
                      <th class="text-center align-middle">{{store.county}}</th>
                      <th class="text-center align-middle">{{store.city}}</th>
                      <th class="text-center align-middle"><img :src= store.qrCode width="100" height="100"></th>
                      <th class="text-center align-middle">{{store.feed_url}}</th>
                      <th class="text-center align-middle">
                        <button type="button" class="btn btn-sm btn-warning" @click="editStore(index)">{{language.edit}}</button>
                        <button type="button" class="btn btn-sm btn-danger" @click="openAlert(store.id)">{{language.delete}}</button>
                      </th>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </card>
        </div>
      </div>
    </div>
    <modal v-model:show="newStoreModal"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary" shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0">
        <div class="text-center text-muted mb-4">
          <small>{{language.add_new_store}}</small>
        </div>
        <!-- Add new user form -->
        <form @submit.prevent>
          <div class="form-group input-group input-group-alternative">
            <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-shop"></i>
                    </span>
            </div>
            <input
                :placeholder="language.store_name"
                class="form-control input-group-alternative"
                v-model="newStore.store_name"
                focused
            />
          </div>
          <div class="input-errors" v-if="Object.keys(errors).length > 0 && 'store_name' in errors">
            <div class="text-red mb-2 mt--3">{{ errors['store_name'][0]}}</div>
          </div>
          <div class="form-group input-group input-group-alternative">
            <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-square-pin"></i>
                    </span>
            </div>
            <input
                :placeholder="language.location"
                type="text"
                class="form-control input-group-alternative"
                v-model="newStore.location"
            />
          </div>
          <div class="input-errors" v-if="Object.keys(errors).length > 0 && 'location' in errors">
            <div class="text-red mb-2 mt--3">{{ errors['location'][0]}}</div>
          </div>

          <div class="form-group input-group input-group-alternative">
            <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-world"></i>
                    </span>
            </div>
            <select class="form-control" v-model="newStore.country" @change="getCounties">
              <option value="" disabled selected>{{language.select_country}}</option>
              <template v-for="country in $store.state.countries">
                <option :value="country">{{country}}</option>
              </template>
            </select>
          </div>
          <div class="input-errors" v-if="Object.keys(errors).length > 0 && 'country' in errors">
            <div class="text-red mb-2 mt--3">{{ errors['country'][0]}}</div>
          </div>

          <div class="form-group input-group input-group-alternative">
            <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-world"></i>
                    </span>
            </div>
            <select class="form-control" v-model="newStore.county" @change="getCities">
              <option value="" disabled selected>{{language.select_county}}</option>
              <template v-for="county in counties">
                <option :value="county.name">{{county.name}}</option>
              </template>
            </select>
          </div>
          <div class="input-errors" v-if="Object.keys(errors).length > 0 && 'county' in errors">
            <div class="text-red mb-2 mt--3">{{ errors['county'][0]}}</div>
          </div>

          <div class="form-group input-group input-group-alternative">
            <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-world"></i>
                    </span>
            </div>
            <select class="form-control" v-model="newStore.city" @change="getCities">
              <option value="" disabled selected>{{language.select_city}}</option>
              <template v-for="city in cities">
                <option :value="city.name">{{city.name}}</option>
              </template>
            </select>
          </div>
          <div class="input-errors" v-if="Object.keys(errors).length > 0 && 'city' in errors">
            <div class="text-red mb-2 mt--3">{{ errors['city'][0]}}</div>
          </div>

          <div class="form-group input-group input-group-alternative">
            <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-laptop"></i>
                    </span>
            </div>
            <input type="text" :placeholder="language.product_feed_url" class="form-control" v-model="newStore.feed_url"/>
          </div>
          <div class="input-errors" v-if="Object.keys(errors).length > 0 && 'feed_url' in errors">
            <div class="text-red mb-2 mt--3">{{ errors['feed_url'][0]}}</div>
          </div>

          <!-- Submit form button -->
          <div class="text-center">
            <base-button type="primary" class="my-4" @click="addStore" v-if="editMode === false">{{language.save}}</base-button>
            <base-button type="primary" class="my-4" @click="updateStore" v-if="editMode === true">{{language.save}}</base-button>
          </div>
        </form>
      </card>
    </modal>
  </div>
</template>

<script>
import HelpersService from "../services/HelpersService";
import StoreService from "../services/StoreService";
import {
  ElNotification
} from "element-plus";

export default {
  name: "Stores",
  components: {
    [ElNotification.name]: ElNotification
  },
  data() {
    return {
      language: '',
      newStoreModal: false,
      newStore: {
        store_name: '',
        location: '',
        country: '',
        county: '',
        city: '',
        feed_url: '',
      },
      errors: {},
      serverError: '',
      counties: [],
      cities: [],
      stores: '',
      editMode: false
    }
  },
  methods: {
   async addStore() {
     this.errors = {};
     this.serverError = [];
     this.editMode = false;
      try {
        let response = await StoreService.saveStore(this.newStore);
        if(typeof response.data.errors !== 'undefined') {
          this.errors = Object.assign(this.errors,response.data.errors);
        } else {
          let data = {};
          data['store_id'] = response.data.store.id;
          data['url'] = response.data.store.feed_url;

          let importNotification = ElNotification.info({
            title: 'Store Creation',
            message: 'Products feed is importing',
            duration: 0
          })
          await StoreService.importProducts(data);
          this.newStoreModal = false;
          importNotification.close();
          let storesData = await StoreService.getUserStores();
          this.stores = storesData.data.stores;
          ElNotification({
            title: 'Store creation',
            message: 'Store created successfully',
            type: 'success',
          })
        }
      } catch (e) {
        this.serverError = e;
        ElNotification({
          title: 'Store creation',
          message: 'Store creation error',
          type: 'error',
        })
      }

    },
    async getCounties() {
      if(this.newStore.country) {
        this.counties = (await HelpersService.indexStatesAndCities(this.newStore.country)).data.states
      }
    },
    async getCities() {
      if(this.newStore.country && this.newStore.county) {
        this.cities = (await HelpersService.indexStatesAndCities(this.newStore.country, this.newStore.county)).data.cities
      }
    },
    editStore(index) {
      this.editMode = true;
      this.newStore.country = this.stores[index].country;
      this.newStore.county = this.stores[index].county;
      this.getCounties();
      this.newStore.city = this.stores[index].city;
      this.getCities();
      this.newStore.store_name = this.stores[index].store_name;
      this.newStore.location = this.stores[index].location;
      this.newStore.feed_url = this.stores[index].feed_url;
      this.newStore.id = this.stores[index].id;
      this.newStoreModal = true;
    },
    async updateStore() {
      this.errors = {};
      this.serverError = [];
      this.editMode = false;
      try {
        let response = await StoreService.updateUserStore(this.newStore);
        if(typeof response.data.errors !== 'undefined') {
          this.errors = Object.assign(this.errors,response.data.errors);
        } else {
          this.newStoreModal = false;
          let updateNotification = ElNotification.info({
            title: 'Store update',
            message: 'Products feed is updating',
            duration: 0
          })
          let data = {};
          data['store_id'] = this.newStore.id;
          data['url'] = this.newStore.feed_url;
          await StoreService.importProducts(data);
          updateNotification.close();
          let storesData = await StoreService.getUserStores();
          this.stores = storesData.data.stores;
          ElNotification({
            title: 'Store update',
            message: 'Store updated successfully',
            type: 'success',
          })
        }
      } catch (e) {
        this.serverError = e;
        ElNotification({
          title: 'Store edit',
          message: 'Store update error',
          type: 'error',
        })
      }
    },
    async deleteUserStore(storeId) {
     try {
       let data = {};
       data['storeId'] = storeId;
       await StoreService.deleteUserStore(data);
       this.$swal(this.language.success, this.language.store_deleted, 'success');
       let storesData = await StoreService.getUserStores();
       this.stores = storesData.data.stores;
     } catch (e) {
       this.serverError = e;
       ElNotification({
         title: 'Store remove',
         message: 'Store remove error',
         type: 'error',
       })
     }
    },
    openAlert(storeId) {
     this.$swal({
       title: this.language.are_you_sure,
       text: this.language.delete_store_text,
       type: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: this.language.delete
     }).then((result) => {
       if(result.value) {
         this.deleteUserStore(storeId);
       }
     });
    }
  },
  async created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },2000);
    this.$store.dispatch("fetchCountries", { self: this })
    let storesData = await StoreService.getUserStores();
    this.stores = storesData.data.stores;
  }
}
</script>

<style scoped>

</style>