import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import AuthAdditionalLayout from "@/layout/AuthAdditionalLayout";

import Profile from "../views/UserProfile.vue";
import Products from "../views/Products.vue";
import Orders from "../views/Orders.vue";
import Pay from "../views/Pay.vue";

// user
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Users from "../views/Users.vue";
import ValidateAccount from "../views/ValidateAccount.vue";
import PopUpConfiguration from "../views/PopUpConfiguration.vue";
import Installation from "../views/Installation.vue";
import Reports from "../views/Reports.vue";
import TermsAndConditions from "../views/TermsAndConditions.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Stores from "../views/Stores.vue";

// admin
import DashboardAdmin from "../views/admin/Dashboard.vue";

const routes = [
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      }
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthAdditionalLayout,
    children: [
      {
        path: "/forgot-password",
        name: "Forgot Password",
        components: { default: ForgotPassword },
      },
      {
        path: "/reset-password/:token",
        name: "Reset password",
        components: { default: ResetPassword }
      },
      {
        path: "/verify-email/:token",
        name: "validate",
        components: { default: ValidateAccount },
      },
      {
        path: "/terms-and-conditions",
        name: "terms",
        components: { default: TermsAndConditions },
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        components: { default: PrivacyPolicy },
      },
    ],
  },
  {
    path: "/",
    redirect: "/profile",
    component: DashboardLayout,
    children: [
      {
        path: "/fast-onboarding",
        name: "installation",
        components: { default: Installation },
      },
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
      },
      {
        path: "/products",
        name: "products",
        components: { default: Products },
      },
      {
        path: "/orders",
        name: "orders",
        components: { default: Orders },
      },
      {
        path: "/pay",
        name: "pay",
        components: { default: Pay },
      },
      {
        path: "/users",
        name: "users",
        components: { default: Users },
      },
      {
        path: "/settings",
        name: "settings",
        components: { default: PopUpConfiguration },
      },
      {
        path: "/reports",
        name: "reports",
        components: { default: Reports },
      },
      {
        path: "/stores",
        name: "stores",
        components: { default: Stores },
      },
    ],
  },
  {
    path: "/admin",
    name: "dashboard-admin",
    component: DashboardAdmin,
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  shallow: false,
  routes,
});

export default router;