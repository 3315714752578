import Api from '@/services/Api'

export default {
    orders() {
        return Api.get('orders');
    },

    orders_import(data) {
        return Api.post('orders/import', data);
    }
}