import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import HelpersService from '../services/HelpersService'
import UserService from '../services/UserService'

export default createStore({
    strict: true,
    plugins: [createPersistedState()],
    state: {
        token: null,
        user: {},
        countries: [],
        currencies: [],
        isUserLoggedIn: false,
        isVerified: false,
        isCompleted: false,
        popupConfig: false,
        productsLoaded: false,
        languageVariable: [],
    },
    mutations: {
        setLanguage(state, language) {
            state.languageVariable = language;
        },
        setUserToken (state, token) {
            state.token = token;
            if (token) {
                state.isUserLoggedIn = true;
            } else {
                state.isUserLoggedIn = false;
            }
        },
        setUser (state, user) {
            state.user = user;
        },
        setVerification (state, ok) {
            state.isVerified = ok;
        },
        setDataCompleted (state, ok) {
            state.isCompleted = ok;
        },
        resetState (state) {
            state.user = null;
            state.token = null;
            state.isUserLoggedIn = false;
            state.popupConfig = false;
            state.productsLoaded = false;
            state.isVerified = false;
            state.isCompleted = false;
        },
        setCountries (state, countries) {
            state.countries = countries.map(country => country.name);
        },
        setCurrencies (state, currencies) {
            state.currencies = currencies.map(currency => currency.code);
        },
        setConditions (state, condition) {
            if (condition == 1) {
                state.popupConfig = true;
                state.productsLoaded = true;
            }
        },
        setStep (state, step) {
            state.user.step = step
        }
    },
    actions: {
        setLanguage({commit}, language) {
            commit('setLanguage', language);
        },
        setUserToken ({commit}, token) {
            commit('setUserToken', token);
        },
        setUser ({commit}, user) {
            commit('setUser', user);
        },
        setVerification ({commit}, ok) {
            commit('setVerification', ok);
        },
        setDataCompleted ({commit}, ok) {
            commit('setDataCompleted', ok);
        },
        resetState ({commit}) {
            commit('resetState');
        },
        async fetchCountries ({commit}) {
            commit('setCountries', (await HelpersService.indexCountries()).data.countries);
        },
        async fetchCurrencies ({commit}) {
            commit('setCurrencies', (await HelpersService.indexCurrencies()).data.currencies);
        },
        async fetchUserConditions ({commit}) {
            commit('setConditions', (await UserService.checkUser()).data);
        },
        setStep ({commit}, value) {
            commit('setStep', value);
        }
    }
})