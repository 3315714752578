<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <!-- Success message -->
        <div v-if="emailSent" class="card-body px-lg-5 py-lg-5">
          <div class="text-center mb-2">
            <h3>{{language.check_email}}</h3>
            <p>{{language.reset_pass_email}}</p>
          </div>
        </div>
        <div v-if="!emailSent" class="card-body px-lg-5 py-lg-5">
          <div class="text-center mb-4">
            <h4>{{language.enter_account_email}}</h4>
          </div>
          <!-- Reset password email form -->
          <form role="form">
            <div class="form-group input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-email-83"></i>
                </span>
              </div>
              <input
                placeholder="Email"
                :rules="required"
                class="form-control input-group-alternative"
                v-model="model.email"
                focused
                />
            </div>
            <!-- Server errors -->
            <div class="row my-4">
              <div class="col-12">
                <span class="text-danger" v-html="error"/>
              </div>
            </div>
            <!-- Submit form button -->
            <div class="text-center">
              <base-button
                type="primary"
                class="mb-4"
                @click="sendEmail"
                >{{language.send_mail_password}}</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/login" class="text-light">
            <small>{{language.sign_in}}</small>
          </router-link>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light">
            <small>{{language.create_new_account}}</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'

export default {
  name: "forgot-password",
  data() {
    return {
      model: {
        email: ""
      },
      error: null,
      required: (value) => !!value || 'Required',
      emailSent: false,
      language: ''
    };
  },
  methods: {
    async sendEmail() {
      try {
        this.error = null;
        if (this.model.email === "") {
          this.error = "Please enter your email.";
          return;
        }
        // send reset password email
        await AuthService.forgotPassword({
          email: this.model.email,
        })

        this.emailSent = true;
      } catch (error) {
        // save server errors
        if (error.response.status == 404) {
          this.error = error.response.data.errors.message
        } else if (error.response.status == 422) {
          console.log(error.response)
          this.error = error.response.data.errors.email
        } else if (error.response.status == 500) {
          this.error = 'Sorry! An error has occured. Please check if the email is valid.'
        }
      }
    }
  },
  created() {
    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  }
};
</script>
<style></style>
