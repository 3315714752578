<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 300px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      ">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <h1 class="display-2 text-white">{{language.fast_onboarding}}</h1>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary" class="pb-7">
            <!-- Usefull links form -->
            <div class="row steps">
              <div class="step">
                <div ref="step1" class="step-number mt-2 mb-2">1</div>
                <div class="vl"></div>
              </div>
              <form @submit.prevent id="configureLinks" class="links-form step-content">
                <h6 class="heading text-muted mb-4 mt-3 step-header">{{language.step_1}} <span>(10 mins)</span></h6>
                
                <div class="col-lg-12 pl-0">
                  <p>{{language.step_0_text}}</p>
                  <div class="form-group input-group input-group-alternative mt-2">
                    <input
                      :placeholder="language.company_url"
                      class="form-control input-group-alternative"
                      v-model="formData.company_url"
                      focused
                      name="company_url"
                      />
                  </div>
                  <!-- Validation error -->
                  <div class="input-errors" v-for="(error, index) of v$.formData.company_url.$errors" :key="index">
                    <div class="text-red mt--3 mb-2">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="col-lg-12 pl-0">
                  <p>{{language.step_1_text}}</p>
                  <div class="form-group input-group input-group-alternative mt-2">
                    <input
                      :placeholder="language.product_url"
                      class="form-control input-group-alternative"
                      v-model="formData.product_url"
                      focused
                      name="product_url"
                      />
                  </div>
                  <!-- Validation error -->
                  <div class="input-errors" v-for="(error, index) of v$.formData.product_url.$errors" :key="index">
                    <div class="text-red mt--3 mb-2">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="col-lg-12 pl-0">
                  <p>{{language.step_2_text}}</p>
                  <div class="form-group input-group input-group-alternative mt-2">
                    <input
                      :placeholder="language.cart_url"
                      class="form-control input-group-alternative"
                      v-model="formData.cart_url"
                      focused
                      name="cart_url"
                      />
                  </div>
                  <!-- Validation error -->
                  <div class="input-errors" v-for="(error, index) of v$.formData.cart_url.$errors" :key="index">
                    <div class="text-red mt--3 mb-2">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="col-lg-12 pl-0">
                  <p>{{language.step_3_text}}</p>
                  <div class="form-group input-group input-group-alternative mt-2">
                    <input
                      :placeholder="language.checkout_url"
                      class="form-control input-group-alternative"
                      v-model="formData.checkout_url"
                      focused
                      name="checkout_url"
                      />
                  </div>
                  <!-- Validation error -->
                  <div class="input-errors" v-for="(error, index) of v$.formData.checkout_url.$errors" :key="index">
                    <div class="text-red mt--3 mb-2">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="col-lg-12 pl-0">
                  <p>{{language.step_4_text}}</p>
                  <div class="form-group input-group input-group-alternative mt-2">
                    <input
                      :placeholder="language.success_order_url"
                      class="form-control input-group-alternative"
                      v-model="formData.success_order_url"
                      focused
                      name="success_order_url"
                      />
                  </div>
                  <!-- Validation error -->
                  <div class="input-errors" v-for="(error, index) of v$.formData.success_order_url.$errors" :key="index">
                    <div class="text-red mt--3 mb-2">{{ error.$message }}</div>
                  </div>
                </div>
                <!-- Submit form button -->
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 text-right">
                        <button type="submit" class="btn btn-primary my-1" @click="saveLinks">{{language.save}}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="row steps mt-6">
              <div class="step">
                <div ref="step2" class="step-number mt-2 mb-2">2</div>
                <div class="vl"></div>
              </div>
              <div class="step-content">
                <h6 class="heading text-muted mb-4 mt-3 step-header">{{language.step_2}} <span>(5 mins)</span></h6>
                <form @submit.prevent id="configureLinks">
                  <h4 class="text-muted mt-3 step-header">Use Google Merchant Feed (the fastest option) or a Custom Product Feed </h4>
                  <p>The feed will be periodically crawled and the item catalog updated.</p>
                  <div class="row">
                    <div class="text-center col-lg-6 col-md-6 col-s-12">
                        <el-radio class="mx-auto" v-model="radio1" label="google" border @change="feed_radio;" @click="googleAuthModal = true;">Google Merchant Feed</el-radio>
                        <p>
                          <a href="https://support.google.com/merchants/answer/7052112?hl=en" target="_blank">
                            <el-button href="https://support.google.com/merchants/answer/7052112?hl=en" style="margin-left: 10px;"  type="warning" circle> 
                              <i class="el-icon-info"></i> 
                            </el-button>
                          </a>
                        </p>
                    </div>
                    <div class="text-center col-lg-6 col-md-6 col-s-12">
                        <el-radio class="mx-auto" v-model="radio1" label="custom" border @change="feed_radio">Custom Feed</el-radio>
                        <p>
                          <el-popover
                            :visible="visible"
                            placement="bottom"
                            :width="350"
                            trigger="manual"
                          >
                            <div style="text-align: center;">
                              <h2>Custom feed</h2>
                              <p  style="font-size: 1rem;">For using the custom feed you need to <br>create a .csv file of your products using <el-link style="color: green; font-weight: bold;" href="https://api.platforma-smart.agentiawebmagnat.ro/public/custom_feed_template.csv">this</el-link> <br>template, upload it to your online shop and <br>provide the link below.</p>
                            </div>
                            <template #reference>
                              <el-button style="margin-left: 10px;" type="warning" @click="visible = !visible" circle> <i class="el-icon-info"></i> </el-button>
                            </template>
                          </el-popover>
                        </p>
                    </div>
                  </div>
                  <div class="col-lg-12 pl-0">
                    <div class="form-group input-group input-group-alternative mt-2">
                      <input
                        :placeholder="language.feed_url"
                        class="form-control input-group-alternative"
                        v-model="feedUrl"
                        focused
                        name="feed_url"
                        :disabled="currentStep < 2"
                        />
                    </div>
                    <!-- Validation error -->
                    <div class="input-errors" v-for="(error, index) of v$.formData.success_order_url.$errors" :key="index">
                      <div class="text-red mt--3 mb-2">{{ error.$message }}</div>
                    </div>
                  </div>
                  <!-- Submit form button -->
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12 text-right">
                          <button type="submit" :disabled="currentStep < 2" class="btn btn-primary my-1" @click="nextStep('step2'); import_feed();">{{language.save}}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row steps mt-6">
              <div class="step">
                <div ref="step3" class="step-number mt-2 mb-2">3</div>
                <div class="vl"></div>
              </div>
              <div class="step-content">
                <h6 class="heading text-muted mb-4 mt-3 step-header">{{language.step_3}} <span>(5 mins)</span></h6>
                <!-- Installation code snippet -->
                <pre class="line-numbers code-snippet code-snippet-custom">
                  <code class="language-markup">
                    {{ installer }}
                  </code>
                </pre>
                <p>{{language.help}}</p>
                <div class="videos-container">
                  <div class="video-player">
                    <h3>For Google Tag Manager</h3>
                    <video class="video-content" controls>
                      <source src="../assets/videos/Google_Tag_Manager_Tutorial.mp4" type="video/mp4">
                      Your browser doesn't support the video tag.
                    </video>
                  </div>
                  <div class="video-player">
                    <h3>For Magento</h3>
                    <video class="video-content" controls>
                      <source src="../assets/videos/Magento_Tutorial.mp4" type="video/mp4">
                      Your browser doesn't support the video tag.
                    </video>
                  </div>
                  <div class="video-player">
                    <h3>For Wordpress</h3>
                    <video class="video-content" controls>
                      <source src="../assets/videos/WordPress_Tutorial.mp4" type="video/mp4">
                      Your browser doesn't support the video tag.
                    </video>
                  </div>
                </div>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 text-right mt-6">
                        <button type="submit" :disabled="currentStep < 3" class="btn btn-primary my-1" @click="nextStep('step3')">{{language.done}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </card>
        </div>
      </div>
    </div>
    <modal v-model:show="googleAuthModal"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary" shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0">
        <div class="text-center text-muted mb-4">
          <p>{{language.google_merchant_text}}</p>
        </div>
        <div class="text-center">
          <base-button type="primary" class="my-4 mx-4" @click="googleAuthModal = false; merchantIdModal = true;">{{language.yes}}</base-button>
          <base-button type="primary" class="my-4 mx-4" @click="googleAuthModal = false">{{language.no}}</base-button>
        </div>
      </card>
    </modal>
    <modal v-model:show="merchantIdModal"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary" shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0">
        <div class="text-center text-muted mb-4">
          <p>{{language.insert_merchant_id}}</p>
        </div>
        <input
            placeholder="Merchant id"
            class="form-control input-group-alternative"
            v-model="merchantId"
            focused
            name="merchant_id"
        />
        <div class="text-center">
          <base-button type="primary" class="my-4 mx-4" @click="loginToGoogle">{{language.submit}}</base-button>
          <base-button type="primary" class="my-4 mx-4" @click="merchantIdModal = false">{{language.cancel}}</base-button>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import LinksService from '../services/LinksService';
import { required } from '@vuelidate/validators';
import ProductService from '../services/ProductService';
import UserService from '../services/UserService';
import GoogleAuthService from '../services/GoogleAuthService';
import { defineComponent, ref } from 'vue'

import {
  ElNotification,
  ElRadio,
  ElPopover,
  ElLink,
  ElButton,
  ElIcon
} from "element-plus";
import axios from "axios";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    [ElNotification.name]: ElNotification,
    [ElRadio.name]: ElRadio,
    [ElPopover.name]: ElPopover,
    [ElLink.name]: ElLink,
    [ElButton.name]: ElButton,
    [ElIcon.name]: ElIcon
  },
  data () {
    return {
      installer: '',
      alert: '',
      formData: {
        company_url: '',
        product_url: '',
        cart_url: '',
        checkout_url: '',
        success_order_url: ''
      },
      links: {},
      language: '',
      feedUrl: '',
      radio1:'',
      googleAuthModal: false,
      merchantIdModal: false,
      merchantId: '',
      currentStep: 1,
      visible: ref(false),
    }
  },
  async created () {
    Prism.highlightAll()
    this.getLinks()
    this.getFeedData()
    this.setInstaller()
    this.setStep()

    setTimeout(() => {
      this.language = this.$store.state.languageVariable;
    },100);
  },
  methods: {
    setStep() {
      this.currentStep = parseInt(this.$store.state.user.step);
    },
    setInstaller() {
      // The script to be installed in client's shop
      const scriptTag = 'script'
      this.installer =`
      <${scriptTag} src="https://cdn.platforma-smart.ro/script/load.min.js"></${scriptTag}>
      <${scriptTag}>
        window.TrackUI.record({
          apiToken: "${this.$store.state.user.authorization_token}",
          searchFieldClass: "search-field",
          clientId: "${this.$store.state.user.client_id}",
          debug: true,
        });
      </${scriptTag}>`;
    },
    async getFeedData () {
      // Get feed data from database  only if it exists 
      this.companyData = (await UserService.getUserDetails()).data.company;
      if(this.companyData !== null){
        this.nextStep('step2');
        this.radio1 = this.companyData.feed;
        this.feedUrl = this.companyData.feed_url;
        this.feed_radio(this.companyData.feed);
      }
    },
    async getLinks () {
      // Get saved links from database if they exist
      this.links = (await LinksService.index()).data.links
      if (this.links !== null) {
        this.nextStep('step1')
        this.formData.company_url = this.links.company_url
        this.formData.product_url = this.links.product_url
        this.formData.cart_url = this.links.cart_url
        this.formData.checkout_url = this.links.checkout_url
        this.formData.success_order_url = this.links.success_order_url
      }
    },
    async saveLinks () {
      // Save links
      this.v$.formData.$touch()
      if (this.v$.formData.$pending || this.v$.formData.$error) return
      let form = document.getElementById("configureLinks")
      let data = new FormData(form)

      /**
       * Make POST request if the popup has no saved data
       * Make PUT request if the data is updated
       */
      if (this.links === null) {
        try {
          await LinksService.create(data)
          this.getLinks()
          this.$swal('Saved', 'Links saved successfully!', 'success')
        } catch (err) {
          console.log(err)
          this.$swal('Error', 'Sorry! An error has occured. Please check if the links are valid.', 'error')
        }
      } else {
        try {
          data.append( '_method', 'PUT' );
          await LinksService.update(data, this.links.company_id)
          this.getLinks()
          this.$swal('Updated', 'Links updated successfully!', 'success')
        } catch (err) {
          console.log(err)
          this.$swal('Error', 'Sorry! An error has occured. Please check if the links are valid.', 'error')
        }
      }

      this.getLinks
    },

    nextStep(step) {
      if (this.$refs[step]) {
        this.$refs[step].innerHTML = ""
        this.$refs[step].classList += " success"
      }
      if (step === 'step1') {
        this.$store.dispatch('setStep', "2");
        this.currentStep = 2;
      } else if (step === 'step2') {
        this.currentStep = 3;
        this.$store.dispatch('setStep', "3");
      }
    },

    async feed_radio(label){

      if (label === 'custom'){
        this.selection = 2;
      }

      if (label === 'google'){
        this.selection = 1;
      }
    },

    async import_feed() {
      ElNotification.info({
        title: 'Products Feed',
        message: 'Products feed is importing',
        duration: 5000
        })
        
      if (this.selection === 1){
      try {
          await ProductService.google_feed({url: this.feedUrl})
          ElNotification({
              title: 'Products Feed',
              message: 'Products successfully imported',
              type: 'success',
            })
          this.$store.dispatch('setDataCompleted', true);
          this.nextStep('step2');
        } catch (err) {
          console.log(err)
          ElNotification({
              title: 'Products Feed',
              message: 'Import error',
              type: 'error',
            })
        }
      }
      if (this.selection === 2){
        try {
          await ProductService.custom_feed({url: this.feedUrl})
          ElNotification({
              title: 'Products Feed',
              message: 'Products successfully imported',
              type: 'success',
            })
          this.$store.dispatch('setDataCompleted', true);
          this.nextStep('step2');
        } catch (err) {
          console.log(err)
          ElNotification({
              title: 'Products Feed',
              message: 'Import error',
              type: 'error',
            })}}
    },
    async loginToGoogle() {
      try {
        const authCode = await this.$gAuth.getAuthCode();
        const response = await GoogleAuthService.google_login({auth_code: authCode, merchant_id: this.merchantId});
        this.merchantIdModal = false;

        if(response.status === 201) {
          ElNotification({
            title: 'Products Feed',
            message: 'Products successfully imported from Google Merchant',
            type: 'success',
          })
          this.$store.dispatch('setDataCompleted', true);
          this.nextStep('step2');
        }
      } catch (err) {
        ElNotification({
          title: 'Products Feed',
          message: 'Products not imported from Google Merchant',
          type: 'error',
        })
      }
    }
  },
  // Validations for links form
  validations() {
    return {
      formData: {
        company_url: { required },
        product_url: { required },
        cart_url: { required },
        checkout_url: { required },
        success_order_url: { required }
      },
    }
  },
}
</script>
